import React, { Component } from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import { PortalUnicoLanding } from './landing';
import { Ministerio } from './ministerio';
import { Reparticion } from './reparticion';
import { Actividad } from './actividad';
import { createBrowserHistory } from 'history';


const history = createBrowserHistory();

function redirect(props) {
	window.location.replace('/');
}

function renderPortalUnicoLanding(props) {
	return (
		<PortalUnicoLanding
			{...props}
			loadingStateCounter={this.props.loadingStateCounter} />
	);
}

function renderMinisterio(props) {
	return (
		<Ministerio
			{...props}
			loadingStateCounter={this.props.loadingStateCounter} />
	);
}

function renderReparticion(props) {
	var param = props.match.params.reparticionId;
	if (/\D+/.test(param) && param != "buscar") {//*ecarpiod
		return redirect(props);
	}
	if (param == "buscar") {//*ecarpiod
		param = 0;
	}
	return (
		<Reparticion
		{...props}
		reparticion={param}
		loadingStateCounter={this.props.loadingStateCounter}/>
	);

}

function renderActividad(props) {
	return (
		<Actividad
			{...props}
			loadingStateCounter={this.props.loadingStateCounter} />
	);
}


class Body extends Component {
	render () {
	  return (
			<HashRouter history={history}>
				<Switch>
						<Route
							exact path="/"
							render={renderPortalUnicoLanding.bind(this)} />
						<Route
							exact path="/area/:nombreMinisterio"
							render={renderMinisterio.bind(this)} />
							{/* izaregon535 */}
						<Route
							exact path="/actividad/:idEncuentro"
							render={renderActividad.bind(this)} />
						<Route
							path='/actividades/:reparticionId'
							render={renderReparticion.bind(this)} />
						<Route render={redirect} />
				</Switch>
			</HashRouter>
	  );
	}
  }
  
  export default Body;
