
import React, { Component } from 'react';

class Footer extends Component {
	
	constructor() {
		super();
		this.versionx='00.12.00';
		this.GCBA_URL_PREFIX = "http://www.buenosaires.gob.ar";
	}
	render () {
	// console.log(this.version+'.RC-1');

		return (
			<div className="footer footer-buenosaires">
				<div className="container">
					<div className="row">
						<div className="col-md-3 col-sm-12 col-xs-12 logoVBA">
							<a className="navbar-brand bavos-footer" href="http://www.buenosaires.gob.ar" target="_blank" rel="noopener noreferrer"></a>
						</div>
						<div className="col-md-3 col-sm-6 col-xs-12 acercaSitio">
							<p>ACERCA DE ESTE SITIO</p>
							<ul>
								<div className="region region-footer-four">
									<h4></h4>
									<ul><li className=""><a target="_blank" rel="noopener noreferrer" href={this.GCBA_URL_PREFIX + "/innovacion/ciudadinteligente/terminos-y-condiciones"} title="">Términos y condiciones</a></li>
										<li className=""><a target="_blank" rel="noopener noreferrer" href={this.GCBA_URL_PREFIX + "/privacidad"} title="">Política de privacidad</a></li>
										<li className=""><a target="_blank" rel="noopener noreferrer" href={this.GCBA_URL_PREFIX + "/innovacion/ciudadinteligente/gestiondigital/webs-y-apps"} title="">Sobre esta web</a></li>
										<li className=""><a target="_blank" rel="noopener noreferrer" href={this.GCBA_URL_PREFIX + "/mapadesitio"} title="">Mapa de sitio</a></li>
									</ul>  
								</div>
							</ul>
						</div>
						<div className="col-md-3 col-sm-6 col-xs-12 telefonosUtiles">
							<p>TELÉFONOS DE EMERGENCIA</p>
							<ul>
								<li>
									<a href="tel:103">
										<span className="glyphicon glyphicon-earphone"></span>
										<div>103 | Emergencias</div>
									</a>
								</li>
								<li>
									<a href="tel:107">
										<span className="glyphicon glyphicon-earphone"></span>
										<div>107 | SAME</div>
									</a>
								</li>
								<li>
									<a href="tel:108">
										<span className="glyphicon glyphicon-earphone"></span>
										<div>108 | Línea Social</div>
									</a>
								</li>
								<li>
									<a href="tel:147">
										<span className="glyphicon glyphicon-earphone"></span>
										<div>147 | Atención Ciudadana</div>
									</a>
								</li>
								<li>
									<a href="tel:144">
										<span className="glyphicon glyphicon-earphone"></span>
										<div>144 | Violencia de Género</div>
									</a>
								</li>
							</ul>
						</div>
						<div className="col-md-3 col-sm-12 col-xs-12">
							<p className="hidden-xs hidden-sm">CONTACTO</p>
							<hr className="hidden-lg hidden-md" />
	
							<ul className="social">
								<li><a className="social-fb" href="http://www.facebook.com/gcba" target="_blank" rel="noopener noreferrer">Facebook</a></li>
								<li><a className="social-tw" href="http://www.twitter.com/gcba" target="_blank" rel="noopener noreferrer">Twitter</a></li>
								<li><a className="social-yt" href="http://www.youtube.com/user/GCBA" target="_blank" rel="noopener noreferrer">Youtube</a></li>
								<li><a className="social-in" href="https://www.instagram.com/buenosaires/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
								<li><a className="social-rss" href={this.GCBA_URL_PREFIX + "/rss/noticias"} target="_blank" rel="noopener noreferrer">RSS</a></li>
							</ul>
						</div>
						<div className="col-md-12 col-sm-12 col-xs-12 legal">
							<hr />
							<div className="row">
								<div className="col-md-6 col-sm-6 col-xs-12 legalText pull-right">
									<p>
										Los contenidos de Gobierno de la Ciudad Autónoma de Buenos Aires están licenciados bajo Creative Commons Reconocimiento 2.5 Argentina License
									</p>
									<p>
										Versión {this.props.version}
									</p>
								</div>
								<div className="logoOficialCiudad col-md-6 col-sm-6 col-xs-12 pull-left">
									<span>
										<a className="navbar-brand bac-footer" href="http://www.buenosaires.gob.ar" target="_blank" rel="noopener noreferrer">Buenos Aires Ciudad</a>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
  }
  
  export default Footer;