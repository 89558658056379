import React, { Component} from 'react';
import { withRouter } from "react-router";
import $ from 'jquery';
import { Jumbotron, Breadcrumb, BreadcrumbLinkLanding } from './heading-components';

const SERVICE_URL_DOMAIN = "/destinations/GCBA_IOGEN_PU";

// export const nombreMinisterio = (window.location.hash).replace("#/ministerio/","");

class Promocion extends Component {
	constructor(props) {
		super(props);
		this.handleOnClick = this.handleOnClick.bind(this);
	}

	handleOnClick() {
		// if(this.props.area.id==="5"){
			
		// 	window.location.href ="https://www.buenosaires.gob.ar/inscripcionbibliotecas";
		// 	// izaregon805
		// }else{
		this.props.history.push("/actividades/" +  this.props.area.id);		
	}
	
	render() {
		
		// "col-xs-12 col-sm-6 col-md-4 col-lg-3 m-landing-ministerio " 
	//	var sContainerClassName = "col-xs-12 col-sm-6 col-md-4 col-lg-3 m-landing-ministerio ";
		var sContainerClassName =" col-sm-6 col-md-3 m-landing-ministerio shortcutPerfil ";
		return (
			// izaregon543
			
			<div className="m-cultura-landing" style={{display:(this.props.area["estado.id"]==="0")?"block" : "none"}} >
				<div className={sContainerClassName} onClick={this.handleOnClick}>
						<span className="promocion-cultural"
						style ={{ backgroundColor: `${this.props.area.color}` }}>
							<span className="promocion-cultural-img" 
							style ={{ backgroundImage: `url(${this.props.area.logo})` }}>
								</span></span><h3>{this.props.area.descripcion}</h3>
								<p>{this.props.area.detalle}</p>
				</div>
			</div>		
		);
	}
}

const PromocionWithRouter = withRouter(Promocion);

class ReparticionesContainer extends Component {
	render() {
		return (
			<div className="container">
				{this.props.areas.map(
					function(area) {
						return (<PromocionWithRouter key={area.id} area={area} />);
					})}
			</div>
		);
	}
}



class BreadcrumbMinisterio extends Component {
	render() {
		return (
			<Breadcrumb descripcion={this.props.ministerio.descripcion}>
				<BreadcrumbLinkLanding />
			</Breadcrumb>
		);
    }
}

class JumbotronMinisterio extends Component {
	render() {
        var m = this.props.ministerio;
        return (
			<Jumbotron
				banner={m.banner}
				titulo={m.titulo}
				detalle={m.detalle}>
				<BreadcrumbMinisterio ministerio={m} />
			</Jumbotron>
		);
	}
}

export class Ministerio extends Component {
		constructor(props) {
			super(props);
			this.state = {
				ministerioUrl: props.match.params.nombreMinisterio,
				ministerio: {},
				areas: []
			};
		}
		
		componentDidMount() {
			
			window.scrollTo(0,0);
			this.props.loadingStateCounter.increase();
			$.ajax({
				url: SERVICE_URL_DOMAIN + "/MasterService.xsodata/Ministerio",
				data: {
					$select: "id,titulo,descripcion,banner,detalle",
					$filter: `url eq '${this.state.ministerioUrl}'`,
					$format: "json",
				},
				dataType: 'json',
				cache: false
			}).done(
					function (data) {
						var r = (data && data.d && data.d.results ? data.d.results : []);
						if(r.length===0){window.location.replace('/');}
						r = r.length > 0 ? r[0] : r;
						this.setState({ministerio: r});
						this.props.loadingStateCounter.decrease();
					}.bind(this)
				).fail(/* sarasa */);
			this.props.loadingStateCounter.increase();
			$.ajax({
				url: SERVICE_URL_DOMAIN + "/MasterService.xsodata/Reparticion",
				data: {
					$select: "id,logo,orden,descripcion,detalle,codigo,banner,color,estado.id",
					$orderby: 'orden asc',
					$expand: "Ministerio",
					$filter: "reciclado eq 0 and estado.id eq 0 and Ministerio/url eq '" + this.state.ministerioUrl + "'"
				}, 
				dataType: 'json',
				cache: false
			})
			.done(
				function( data, textStatus, jqXHR ) {
					var aResults = data && data.d && data.d.results ? data.d.results : [];
					this.setState({areas: aResults});
					this.props.loadingStateCounter.decrease();
				}.bind(this)
			)
			.fail( function( jqXHR, textStatus, errorThrown ) {
				console.log("Error", jqXHR, textStatus, errorThrown );
				alert("Estamos teniendo problemas para obtener la información.");
			})
			// function redirect() {
			// 	window.location.replace('/');
			// }
			// if(!this.props.area && this.props.history.action!=="PUSH"){
			// 	redirect(this.props);
			// }
			
		}
		
		render() {
			return (
				<div className="container">
					<JumbotronMinisterio ministerio={this.state.ministerio} />
					<ReparticionesContainer areas={this.state.areas} />
				</div>
			);
		}
	};
