import React, { Component } from "react";

class ActivityDetailInformationField extends Component {
	render() {
		if (!this.props.text || this.props.text.trim() === '')
			return null;
		var icon = this.props.icon ? this.props.icon : '';
        var colSize = (this.props.colSize ? this.props.colSize : 9);
		var setCaption;
		var mb25;
		var content = this.props.text;
        if (this.props.setCaption !== null && this.props.setCaption !== undefined)
            setCaption = this.props.setCaption;
        else
			setCaption = false;
		if (this.props.mb25 !== null && this.props.mb25 !== undefined)
            mb25 = this.props.mb25;
        else
			mb25 = false;
		if (this.props.strong) {
			content = <strong>{content}</strong>;
		}
		return (
			<div className={`${(setCaption ? 'caption' : '')} ${mb25 ? 'mb25' : ''} col-xs-12`}>
				<span className={`col-xs-1 glyphicon ${icon} text-muted`}></span>
				<span className={`col-xs-${colSize}`}>{content}</span>
			</div>	
		);

	}
}

class ActivityDetailInformationAddressField extends Component {
	_formateoBarrio(sBarrio) {
		if (typeof sBarrio === "string" && sBarrio.length > 0)
			return sBarrio[0].toUpperCase() + (sBarrio.substr(1)).toLowerCase();

		return "";
	}

	render() {
		
		if (this.props.vDireccion===1){//izaregon730
			return null;
		}else{
			if (!this.props.direccion)
				return null;
			var direccion = this.props.direccion;
			if (this.props.barrio)
				direccion += ', ' + this._formateoBarrio(this.props.barrio);	
			return (
				<ActivityDetailInformationField
					setCaption={this.props.setCaption}
					colSize={this.props.colSize}
					icon={this.props.icon}
					text={direccion} />
			);
		}
	}
}

class ActivityDetailInformationDaysField extends Component {
	_formateoDias(sDias) {
		var fDias = sDias.replace(/,/g, ', ');
		var iLastIndexOfComma = fDias.lastIndexOf(',');
		if (iLastIndexOfComma > -1)
			return fDias.substr(0, iLastIndexOfComma) + ' y ' + fDias.substr(iLastIndexOfComma + 1);

		return fDias;
	}

	getFormatedTimeString() {
		var dias = this._formateoDias(this.props.dias);
		if (this.props.horaInicio) {
			if (!this.props.horaFin)
				dias += ` a las ${this.props.horaInicio}`
			else
				dias += ` de ${this.props.horaInicio} a ${this.props.horaFin}`
		}
		return dias;
	}

	render() {
		if (this.props.vHorario===1){//izaregon730
			return null;
		}else{
			if (!this.props.dias)
				return null;
			return (
				<ActivityDetailInformationField
					text={this.getFormatedTimeString()}
					icon={this.props.icon}
					colSize={this.props.colSize}
					setCaption={this.props.setCaption} />
			)
		}
	}
}

class ActivityDetailInformationPriceField extends Component {
	getFormatedPrice() {
		var jsxArancel;
		if (this.props.arancel === "0")
			jsxArancel = <strong>Actividad {this.props.tipo}</strong>//izaregon533
		else
			jsxArancel = <span>{this.props.arancel}</span>
		return jsxArancel;
	}

	render() {
		if (this.props.arancel === null || this.props.arancel === undefined) {
			return null;
		}
        var colSize = (this.props.colSize? this.props.colSize : 9);
        var setCaption;
        if (this.props.setCaption !== null && this.props.setCaption !== undefined)
            setCaption = this.props.setCaption;
        else
            setCaption = false;
        var mb25;
        if (this.props.mb25 !== null && this.props.mb25 !== undefined)
            mb25 = this.props.mb25;
        else
            mb25 = false;
		return (
			<div className={`${setCaption? 'caption' : ''} ${mb25 ? 'mb25' : ''} col-xs-12`}>
				<span className={`col-xs-1 glyphicon ${this.props.icon} text-muted`} style={{ visibility: this.props.arancel === "0" ? "hidden" : "visible" }}></span>
				<span className={`col-xs-${colSize}`}>{this.getFormatedPrice()}</span>
			</div>
		);
	}
}

class ActivityDetailInformationSiteField extends Component {
	render() {
		if (this.props.vSede===1){//izaregon600
			return null;
		}else{
			if (!this.props.nombreSede)
				return null;
			var text = this.props.nombreSede;
			if (this.props.descripcionTipoSede)
				text = `${this.props.descripcionTipoSede} ${text}`;
			return (
				<ActivityDetailInformationField
					text={text}
					icon={this.props.icon}
					colSize={this.props.colSize}
					setCaption={this.props.setCaption} />
			);
		}
	}
}
class ActivityDetailInformationBarrioField extends Component{
	render(){
		if (this.props.vBarrio===1){
			return null;
		}else{
			return this.props.barrio?(<ActivityDetailInformationField
				text={this.props.barrio}
				capitalize={true}
				icon={this.props.icon}
				colSize={this.props.colSize}
				setCaption={this.props.setCaption} />):null;
		}
	}
}

class ActivityDetailInformationDatesField extends Component {
	render() {
		if (this.props.vPeriodo===1){//izaregon600
			return null;
		}
		else{
			if (!this.props.fechaInicio)
				return null;
			var text = this.props.fechaInicio;
			if (this.props.fechaFin)
				text += ` - ${this.props.fechaFin}`;
			return (
				<ActivityDetailInformationField
					text={text}
					icon={this.props.icon}
					colSize={this.props.colSize}
					setCaption={this.props.setCaption} />
			);
		}
	}
}

class ActivityDetailInformationAgeField extends Component { 
	
	
		_generateAgeLimitString() {
			var sAgeLimit;
			if (this.props.edadMinima>0) {//solo numeros,izaregon938
				if (this.props.edadMaxima<500) {
					sAgeLimit = `De ${this.props.edadMinima} a ${this.props.edadMaxima} años`
				} else {
					sAgeLimit = `A partir de ${this.props.edadMinima} años`
				}
			} else if (this.props.edadMaxima<500) {
				sAgeLimit = `Hasta ${this.props.edadMaxima} años`
			} else {
				sAgeLimit = "Para todas las edades"
			}
			return sAgeLimit;
		}
		_generateAgeLimitDateString() {
			var sAgeLimit;
			
					sAgeLimit = `Para edades especificas`
				
			
			return sAgeLimit;
		}
		render() {
			if (this.props.vEdades===1){//izaregon600
				return null;
			}else{
				if (this.props.validFechaNacimiento==1){//izaregon1128
					return (
						<ActivityDetailInformationField
							text={this._generateAgeLimitDateString()}
							colSize={this.props.colSize}
							setCaption={this.props.setCaption}
							icon={this.props.icon}
							strong />
					);
				}else{
					return (
						<ActivityDetailInformationField
							text={this._generateAgeLimitString()}
							colSize={this.props.colSize}
							setCaption={this.props.setCaption}
							icon={this.props.icon}
							strong />
					);
				}
			}
		}
}

class ActivityDetailInformation extends Component {
	render () {
		var colSize = (this.props.colSize ? this.props.colSize : 9)
		// if (this.props.reparticionID==="5" || this.props.actividad.idReparticion==="5") {//izaregon498
			
			return (
				<div>
					<ActivityDetailInformationField
						text={this.props.actividad.requisitos}
						icon={'glyphicon-ok-circle'}
						colSize={colSize}
						setCaption={this.props.setCaption} />
					<ActivityDetailInformationAgeField
						validFechaNacimiento={this.props.actividad.validFechaNacimiento}//izaregon1128
						fechaNacimientoMinima={this.props.actividad.fechaNacimientoMinima}//izaregon1128
						fechaNacimientoMaxima={this.props.actividad.fechaNacimientoMaxima}//izaregon1128
						vEdades={this.props.actividad.vEdades}//izaregon730
						edadMinima={this.props.actividad.edadMin}
						edadMaxima={this.props.actividad.edadMax}
						icon={'glyphicon-chevron-right'}
						colSize={colSize}
						setCaption={this.props.setCaption} />
					<ActivityDetailInformationSiteField
						vSede={this.props.actividad.vSede}//izaregon730
						descripcionTipoSede={this.props.actividad.descripcionTipoSede}
						nombreSede={this.props.actividad.nombreSede}
						icon={'glyphicon-home'}
						colSize={colSize}
						setCaption={this.props.setCaption} />
					<ActivityDetailInformationBarrioField //+ecarpiod
						vBarrio={this.props.actividad.vBarrio}//izaregon730
						barrio={this.props.actividad.barrioSede}
						icon={'glyphicon-home'}
						colSize={colSize}
						setCaption={this.props.setCaption}
					/>
					<ActivityDetailInformationAddressField
						vDireccion={this.props.actividad.vDireccion}//izaregon730
						direccion={this.props.actividad.direccionSede}
						barrio={this.props.barrioSede}
						icon={'glyphicon-map-marker'}
						colSize={colSize}
						setCaption={this.props.setCaption} />
					<ActivityDetailInformationDaysField
						vHorario={this.props.actividad.vHorario}//izaregon730
						dias={this.props.actividad.dias}
						horaInicio={this.props.actividad.horaInicio}
						horaFin={this.props.actividad.horaFin}
						icon={'glyphicon-time'}
						colSize={colSize}
						setCaption={this.props.setCaption} />
					<ActivityDetailInformationDatesField
						vPeriodo={this.props.actividad.vPeriodo}//izaregon600
						fechaInicio={this.props.actividad.fechaInicio}
						fechaFin={this.props.actividad.fechaFin}
						icon={'glyphicon-calendar'}
						colSize={colSize}
						setCaption={this.props.setCaption} />
					<ActivityDetailInformationPriceField
						arancel={this.props.actividad.precioTaller}
						tipo={this.props.actividad.tipo}//izaregon533
						icon={'glyphicon-usd'}
						colSize={colSize}
						setCaption={this.props.setCaption}
						mb25={this.props.pricemb25} />
				</div>
			);
		
	}
}

export { ActivityDetailInformation };
