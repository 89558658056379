import React, { Component } from 'react';
import { withRouter } from "react-router";
import ReactDOM from 'react-dom';
import $ from 'jquery';
import queryString from "query-string";
import { Jumbotron, Breadcrumb, BreadcrumbLinkMinisterio, BreadcrumbLinkLanding } from "./heading-components";
import { ComboBarrio,ComboBarrioGenerico, ComboDisciplinas,ComboProvincias, ComboModalidad, ComboSede, CheckArancel, CheckInscripcionAbierta,CheckCuposDisponibles, InputDesde,InputHasta } from "./filter-components";
import { ActivityDetailInformation } from './activity-detail';
import PopUp from './popup.js';

const SERVICE_URL_DOMAIN = "/destinations/GCBA_IOGEN_PU";

export const BreadcrumbLinkBaires = withRouter(({history}) => (
	<a className="clickable-link" onClick={() => { history.push('/') }}>Buenos Aires</a>
));


class Actividad extends Component {
	constructor(props) {
		super(props);
		this.state = {
			imagen: "",
		};
		this._handleInscriptionPress = this._handleInscriptionPress.bind(this);
	  }
	
	componentDidMount() {
		this.props.loadingStateCounter.increase();
		$.ajax({
			url: SERVICE_URL_DOMAIN + `/Taller.xsodata/Oferta(${this.props.row.idEncuentro})`,
			data: {
				$format: 'json',
				$select: 'imagen'
			},
			dataType: 'json',
			cache: false
		})
		.done( function( data, textStatus, jqXHR ) {
				var oResult = data && data.d ? data.d : "";
				// oResult = oResult.length > 0 ? oResult[0] : oResult;
				if (this.refs["encuentro-" + this.props.row.idEncuentro]) 
					this.setState({imagen: oResult.imagen});
				this.props.loadingStateCounter.decrease();
			}.bind(this)
		)
		.fail( function( jqXHR, textStatus, errorThrown ) {
			console.log("Error", jqXHR, textStatus, errorThrown );
			alert("Ocurrió un error al intentar obtener los talleres. Intente nuevamente por favor");
		});
	}
	
	_handleInscriptionPress() {
		var sRoute = "/actividad/{idEncuentro}";
		sRoute = sRoute.replace("{idEncuentro}", this.props.row.idEncuentro);
		this.props.history.push(sRoute);
	}
	
	_formateoDias(sDias) {
		sDias = sDias.replace(/,/g, ', ');
		var iLastIndexOfComma = sDias.lastIndexOf(',');
		if (iLastIndexOfComma > -1)
			return sDias = sDias.substr(0, iLastIndexOfComma) + ' y ' + sDias.substr(iLastIndexOfComma + 1);
		
		return sDias;
	}
	
	_formateoBarrio(sBarrio) {
		if (typeof sBarrio === "string" && sBarrio.length > 0)
			return sBarrio[0].toUpperCase() + (sBarrio.substr(1)).toLowerCase();
		
		return "";
	}


	render() {
		var isEtiqueta = this.props.row.inscripcionAbierta;
		return (
			<div className="col-lg-4 col-sm-6 col-xs-12 mb-4" ref={"encuentro-" + this.props.row.idEncuentro}>
				<div className="actividad thumbnail">
					<div className="imagen">
						<img src={this.state.imagen} className="img-responsive col-xs-12" />
						{isEtiqueta ? <div className="etiqueta"> Inscripción Abierta </div> : ''}	
					</div>					
					<span className="col-xs-12" style={{display:(this.props.row.vDisciplina===0)?"block" : "none"}	}>{this.props.row.descripcionDisciplina}</span>
					<div className="caption titulo col-xs-12" style={{paddingTop:"0"}}>
						<h3 style={{marginTop:"10px"}}>{this.props.row.nombreEncuentro}</h3>
						<h5 style={{marginTop:"10px"}}>{this.props.row.nombreTaller}</h5>
					</div>
					<ActivityDetailInformation reparticionID={this.props.reparticionID} actividad={this.props.row} colSize={11} setCaption={true} pricemb25={true} />
					<div className="col-xs-12 mb25" style={{height: "25px"}}></div>
					<div className="text-center btn-ver-mas-container col-xs-12">
						<button type="button" className="btn btn-lg btn-primary" onClick={this._handleInscriptionPress}>Ver más</button>
					</div>
				</div>
			</div>
		);
	}	
};

export const ActividadWithRouter = withRouter(Actividad);

class Pagination extends Component {
	render() {
		// var i = 0;
		// var aPaginas = [];
		// for (i = 1; i <= this.props.paginas; i++) {
		// 	aPaginas.push(
		// 		<li key={i} style={{cursor: "pointer"}} className={i === this.props.paginaSeleccionada ? "active" : ""} onClick={this.props.handlePaginationSelect.bind(null, i)}>
		// 			<span>
		// 				{i}
		// 				<span className="sr-only">(current)</span>
		// 			</span>
		// 		</li>
		// 	);
		// }
		return (
			<nav aria-label="talleres-pagination" className="col-xs-12 text-center">
				<div className="text-center">
					<ul className="pagination pagination-lg m-pagination" style={{width: "55%"}}>
					<li style={{cursor: "pointer"}} className={"m-pagination-nav " + (this.props.paginaSeleccionada === 1 ? "disabled" : "")} onClick={this.props.handlePaginationFirst}>
			 			<span>			 				
			 				<span aria-hidden="true" className="glyphicon glyphicon-chevron-left"></span>
							<span aria-hidden="true" className="glyphicon glyphicon-chevron-left"></span>
			 			</span>
			 		</li>									
					<li style={{cursor: "pointer"}} className={"m-pagination-nav " + (this.props.paginaSeleccionada === 1 ? "disabled" : "")} onClick={this.props.handlePaginationBefore}>
						<span>
							<span aria-hidden="true" className="glyphicon glyphicon-chevron-left"></span>
						</span>
					</li>
			 		<li className="active m-current-page">
			 			<span>
			 				{this.props.paginaSeleccionada}
			 				<span className="sr-only">(current)</span>
			 			</span>
			 		</li>
					<li style={{cursor: "pointer",display:(this.props.paginaSeleccionada+1<this.props.paginado.totalPaginas)?"block" : "none"}} className="m-pagination-nav " onClick={this.props.handlePaginationSelect.bind(null,this.props.paginaSeleccionada+1)}>
			 			<span>
							 {this.props.paginaSeleccionada+1}
						 </span>
			 		</li>
					<li style={{cursor: "pointer",display:(this.props.paginaSeleccionada+2<this.props.paginado.totalPaginas)?"block" : "none"}} className="m-pagination-nav " onClick={this.props.handlePaginationSelect.bind(null,this.props.paginaSeleccionada+2)}>
			 			<span>
							 {this.props.paginaSeleccionada+2}
						</span>
			 		</li>
					
					 
					 <li style={{cursor: "pointer",display:(this.props.paginaSeleccionada+2<this.props.paginado.totalPaginas)?"block" : "none"}} className="m-pagination-nav disabled" >
			 			<span>
							 ...
						</span>
			 		</li>
					<li style={{cursor: "pointer",display:(this.props.paginaSeleccionada+1<this.props.paginado.totalPaginas)?"block" : "none"}} className="m-pagination-nav disabled" >
			 			<span>
							 {this.props.paginado.totalPaginas}
						</span>
			 		</li>
					<li style={{cursor: "pointer"}} className={"m-pagination-nav " + (this.props.paginaSeleccionada === this.props.paginado.totalPaginas ? "disabled" : "")} onClick={this.props.handlePaginationNext}>
						<span>
							<span aria-hidden="true" className="glyphicon glyphicon-chevron-right"></span>
						</span>
					</li>

					<li style={{cursor: "pointer"}} className={"m-pagination-nav " + (this.props.paginaSeleccionada === this.props.paginado.totalPaginas ? "disabled" : "")} onClick={this.props.handlePaginationLast}>
			 			<span>			 				
			 				<span aria-hidden="true" className="glyphicon glyphicon-chevron-right"></span>
							<span aria-hidden="true" className="glyphicon glyphicon-chevron-right"></span>
			 			</span>
			 		</li>									
				</ul>
				</div>
			</nav>			
		);
	}
};

class ListadoDeActividadesVista extends Component {
	constructor(props) {
		super(props);
		var encuentrosPorPagina = 12;
		this.baseQueryData = {
			$format: 'json',
			$filter: this.baseQueryFilter(),
			$orderby: 'orden, inscripcionAbierta desc',//izaregon914
			// $orderby: 'orden asc',//izaregon914
			$inlinecount: 'allpages',
			$top: encuentrosPorPagina,
			$skip: 0,
			$select: [
				"validFechaNacimiento","fechaNacimientoMinima","fechaNacimientoMaxima",
					"descripcionDisciplina",
					"oculto",
					"nombreTaller",
					"nombreEncuentro",
					"fechaInicio",
					"fechaFin",
					"horaInicio",
					"horaFin",
					"descripcionTipoSede",
					"nombreSede",
					"direccionSede",
					"precioTaller",
					"idEncuentro",
					"dias",
					"orden",
					"vPeriodo",
					"vDisciplina",
					"vAutorizacion",
					"vSede",
					"vBarrio",
					"vEdades",
					"vDireccion",
					"vHorario",
					"tipo",
					"idTipoTaller",
					"comunaSede",
					"idBarrio",
					"idSede",
					"idDisciplina",
					"barrioSede",
					"fechaMinimaInscripcion",
					"fechaMaximaInscripcion",
					"descripcionEncuentro",
					"latitud",
					"longitud",
					"inscripcionAbierta",
					"edadMin",
					"edadMax"
				].join(',')
		};
		this.state = {
			taller: "",
			actividades: [],
			paginado: {
				encuentrosPorPagina: encuentrosPorPagina,
				totalPaginas: 0,
				paginaSeleccionada: 1
			},
		};

		this.handleInputSearch = this.handleInputSearch.bind(this);
		this._handleSearch = this._handleSearch.bind(this);
		this._handlePaginationBefore = this._handlePaginationBefore.bind(this);
		this._handlePaginationNext = this._handlePaginationNext.bind(this);
		this._handlePaginationLast = this._handlePaginationLast.bind(this);	
		this._handlePaginationFirst = this._handlePaginationFirst.bind(this);		
		this._handlePaginationSelect = this._handlePaginationSelect.bind(this);
	}

	turnPage() {
		this.fetchActividades(this.buildQueryFilter());
	}


	_handleSearch(oEvent) {
		this.props.callbacks.handleSearch(oEvent, this.state.taller);
		this.setState({
			paginado: $.extend(Object.assign(this.state.paginado), {paginaSeleccionada: 1})
		});
	}

	handleInputSearch(oEvent) {
		this.setState({taller: oEvent.target.value});
	}

	handleReturnPressSearch(oEvent) {
		if (oEvent.key && oEvent.key == 'Enter') {//*ecarpiod
			this._handleSearch(oEvent);
		}
	}
	_handlePaginationFirst(){
		// if (this.state.paginado.paginaSeleccionada < this.state.paginado.totalPaginas) {
			var nPagina = 1;
			this.setState({paginado: $.extend(Object.assign(this.state.paginado), {paginaSeleccionada:  nPagina})});
			this.turnPage();
		// }
	}
	_handlePaginationNext() {
		if (this.state.paginado.paginaSeleccionada < this.state.paginado.totalPaginas) {
			var nPagina = this.state.paginado.paginaSeleccionada + 1;
			this.setState({paginado: $.extend(Object.assign(this.state.paginado), {paginaSeleccionada:  nPagina})});
			this.turnPage();
		}
	}
	_handlePaginationLast(){
		if (this.state.paginado.paginaSeleccionada < this.state.paginado.totalPaginas) {
			var nPagina = this.state.paginado.totalPaginas;
			this.setState({paginado: $.extend(Object.assign(this.state.paginado), {paginaSeleccionada:  nPagina})});
			this.turnPage();
		}
	}
	_handlePaginationBefore() {
		if (this.state.paginado.paginaSeleccionada > 1) {
			var nPagina = this.state.paginado.paginaSeleccionada - 1;
			this.setState({paginado: $.extend(Object.assign(this.state.paginado), {paginaSeleccionada:  nPagina})});
			this.turnPage();		
		}
	}
	
	_handlePaginationSelect(iIndex) {
		if (iIndex !== this.state.paginado.paginaSeleccionada) {
			var nPagina = iIndex;
			this.setState({paginado: $.extend(Object.assign(this.state.paginado), {paginaSeleccionada:  nPagina})});
			this.turnPage();

			// var oState = $.extend({}, this.state);
			// oState.paginado.paginaSeleccionada = iIndex;
			// this.setState({paginado: $.extend(Object.assign(this.state.paginado), {paginaSeleccionada:  nPagina})});
			// this.setState(oState);
		}
	}

	fetchActividades(queryFilter) {
		this.props.loadingStateCounter.increase();
		const URL = SERVICE_URL_DOMAIN + "/Taller.xsodata/Oferta";
		var queryData = Object.assign({}, this.baseQueryData);
		queryData = $.extend(
			queryData,
			{
				$filter: queryFilter,
				$skip: (this.state.paginado.paginaSeleccionada - 1) * this.state.paginado.encuentrosPorPagina
			}
		);
		$.ajax({
			url: URL,
			data: queryData,
			dataType: 'json'
		}).done(
			function(data) {
				if(data && data.d && data.d.results) {
					var nPaginas = Math.ceil(data.d.__count / this.state.paginado.encuentrosPorPagina);
					var paginado =  $.extend(
						this.state.paginado, {
							totalPaginas: nPaginas
						});
				// ordenamiento
					var odata=data.d.results;
					// odata.sort((a, b) => a.orden - b.orden);		//izaregon914			
					
					this.setState({
						actividades: odata,
						paginado: paginado
					});
				}
				this.props.loadingStateCounter.decrease();
			}.bind(this)
		).fail(
			function() {
				alert('Estamos teniendo problemas para obtener las actividades');
			}
		);
	}

	baseQueryFilter() {
		return 'estadoEncuentro eq 0 and oculto eq 0' + (this.props.reparticionID != 0 ? ` and idReparticion eq ${this.props.reparticionID}` : '')//*ecarpiod
	}

	buildQueryFilter() {
		var filters = [this.baseQueryFilter()];
		if (this.props.filtros.disciplina != '')//*ecarpiod
			filters.push(`(idDisciplina eq ${this.props.filtros.disciplina})`);
		if (this.props.filtros.barrio != '')//*ecarpiod
			filters.push(`(idBarrio eq ${this.props.filtros.barrio})`);
		if (this.props.filtros.modalidad != '')//*ecarpiod
			filters.push(`(idTipoTaller eq ${this.props.filtros.modalidad})`);
		if (this.props.filtros.centro != '')//*ecarpiod
			filters.push(`(idSede eq ${this.props.filtros.centro})`);
		if (this.props.filtros.arancel)
			filters.push("(precioTaller eq 0)");
		if (this.props.filtros.edadMin) {
			var fEdadMin = [];
			fEdadMin.push(`(edadMin le ${this.props.filtros.edadMin})`);//<=
			fEdadMin.push(`(edadMax ge ${this.props.filtros.edadMin}) `);//>=
			filters.push(`(restriccionEdad eq 0 or (${fEdadMin.join(' and ')}))`);
		}
		if (this.props.filtros.edadMax) {
			var fEdadMax = [];
			fEdadMax.push(`(edadMax ge ${this.props.filtros.edadMax})`);
			fEdadMax.push(`(edadMin le ${this.props.filtros.edadMax})`);
			filters.push(`(restriccionEdad eq 0 or (${fEdadMax.join(' and ')}))`);
		}
		if (this.props.filtros.esDisponible)//izaregon947
			filters.push("(cuposOnlineDisponible eq 1)");
		if (this.props.filtros.esAbierta)
			filters.push("(inscripcionAbierta eq 1)");
		if (this.props.filtros.taller != '') {//*ecarpiod
			filters.push(`(${this.generateSearchQuery(this.props.filtros.taller)})`);
		}
		filters = filters.join(' and ');
		return filters;
	}

	generateSearchQuery(value) {
		function generateSanitizer(text) {
			var accents = {
				a: ['á', 'à', 'â', 'ä'],
				e: ['é', 'è', 'ê', 'ë'],
				i: ['í', 'ì', 'î', 'ï'],
				o: ['ó', 'ò', 'ô', 'ö'],
				u: ['ú', 'ù', 'û', 'ü'],
			};
			var newText = text;
			Object.keys(accents).forEach(function (key) {
				accents[key].forEach(function (each) {
					newText = `replace(${newText}, '${each}', '${key}')`;
				});
			});
			return `tolower(${newText})`;
		}

		function generateConcatenator(textArray) {
			var text = textArray.pop();
			textArray.forEach(
				function (each) {
					text = `concat(${text}, ${each})`;
				}
			);
			return text;
		}

		function sanitizeValue(value) {
			value = value.toLowerCase();
			value = value
				.replace(/á|à|ä|â/g, 'a')
				.replace(/é|è|ë|ê/g, 'e')
				.replace(/í|ì|ï|î/g, 'i')
				.replace(/ó|ò|ö|ô/g, 'o')
				.replace(/ú|ù|ü|û/g, 'u');
			return value;
		}
		var fields = [
			'nombreEncuentro',
			'descripcionEncuentro',
			'descripcionDisciplina',
			'nombreTaller',
			'descripcionTaller',
			'nombreSede',
			'direccionSede',
			'nombreMinsterio',
			'descripcionReparticion',
		];

		var fieldConcat = generateSanitizer(generateConcatenator(fields));
		
		var searchQuery = value.split(' ').map(
			function(word) {
				return `(substringof('${sanitizeValue(word)}', ${fieldConcat}))`
			});
		return searchQuery.join(' or ')
	}

	componentDidMount() {
		this.fetchActividades(this.buildQueryFilter());
	}

	componentDidUpdate(nextProps, nextState) {
		var keys = Object.keys(this.props.filtros);
		var filtersDidUpdate = keys.some(
			function(key) {
				return this.props.filtros[key] != nextProps.filtros[key]//*ecarpiod
			}.bind(this)
		);
		if (filtersDidUpdate) {
			this.setState({
				paginado: $.extend(Object.assign(this.state.paginado), {paginaSeleccionada: 1})
			});
			this.fetchActividades(this.buildQueryFilter());
		}
	}
	
	render() {
		var aListaActividades = this.state.actividades.map(
			function(oActivity) {
				return(
					// 
					<ActividadWithRouter reparticionID={this.props.reparticionID} loadingStateCounter={this.props.loadingStateCounter} row={oActivity} key={oActivity.idEncuentro} />		
				);
			}.bind(this)
		);
		
		if (aListaActividades.length === 0) {
			aListaActividades = (
				<h1>No hay resultados para tu búsqueda</h1>
			);
		}
		
		return (
			<div className="col-xs-12 col-sm-10" style={{marginTop: "24px"}}>
				<div className="col-xs-12">
					<div className="pull-right form-group has-button">
						<input type="text" className="col-xs-12 col-md-4 form-control input-lg" placeholder="Buscar" onChange={this.handleInputSearch} onKeyPress={this.handleReturnPressSearch.bind(this)} />
						<button className="btn" onClick={this._handleSearch} >
							<span className="glyphicon glyphicon-search"></span>
						</button>
					</div>
				</div>
				<div className="col-xs-12 m-cultura-activities-container">
					{aListaActividades}
				</div>
				<Pagination 
					paginaSeleccionada={this.state.paginado.paginaSeleccionada}
					paginado={this.state.paginado}
					handlePaginationNext={this._handlePaginationNext}
					handlePaginationLast={this._handlePaginationLast} 
					handlePaginationFirst={this._handlePaginationFirst} 					
					handlePaginationBefore={this._handlePaginationBefore} 
					handlePaginationSelect={this._handlePaginationSelect} />
			</div>
		)
	}
};

export class ListadoDeActividadesFiltros extends Component {
	render() {
		//retirado por izaregon932
		// if (this.props.reparticionID==="5") {// izaregon498, 5 es la reparticion biblioteca
		// 	return null;

		// } else {
		
			return (
				<div className="col-xs-12 col-sm-2">
					<h3>Filtros</h3>
					<ComboDisciplinas
						filtros={this.props.filtros} reparticionID={this.props.reparticionID}
						/* reparticionId={this.props.reparticionId} */
						onChange={this.props.callbacks.handleDisciplineChange}/>
					<ComboProvincias
						filtros={this.props.filtros} reparticionID={this.props.reparticionID}	
						ministerioURL	={this.props.ministerioURL}				
						onChange={this.props.callbacks.handleProvinceChange}/>
					<ComboBarrio
						filtros={this.props.filtros} reparticionID={this.props.reparticionID}	
						/* reparticionId={this.props.reparticionId} */
						onChange={this.props.callbacks.handleNeighborhoodChange} />
					{/* <ComboComunas
						reparticionId={this.props.reparticionId}
						onChange={this.handleCommuneChange}
						value={this.state.filtros.comuna} /> */}
					<ComboSede
						filtros={this.props.filtros} reparticionID={this.props.reparticionID}
						/* reparticionId={this.props.reparticionId} */
						onChange={this.props.callbacks.handleCenterChange} />
					<ComboModalidad
						filtros={this.props.filtros} reparticionID={this.props.reparticionID}
						/* reparticionId={this.props.reparticionId} */
						onChange={this.props.callbacks.handleModalityChange} />
					<InputDesde
						value={this.props.filtros.edadMin}
						onChange={this.props.callbacks.handleEdadMinChange} />
					<InputHasta
						value={this.props.filtros.edadMax}
						onChange={this.props.callbacks.handleEdadMaxChange} />
					<CheckArancel
						value={this.props.filtros.arancel}
						onChange={this.props.callbacks.handleisPayChange} />
					<CheckInscripcionAbierta
						value={this.props.filtros.esAbierta} 
						onChange={this.props.callbacks.handleisOpenChange} />
						{/* izaregon947 */}
					<CheckCuposDisponibles
						value={this.props.filtros.esDisponible} 
						onChange={this.props.callbacks.handleisDisponibleChange} />	
					<button type="button" className="btn btn-primary col-xs-12" onClick={this.props.callbacks.handleCleanFilters}>Limpiar filtros</button>
					
					{ this.props.reparticionID == 1 ? <div className="flyer_pase_cultura">{/* *ecarpiod */}
						<a href="https://disfrutemosba.pasecultural.buenosaires.gob.ar/#/inicio">	
							<img src="img/flyer_pase_cultura.jpg" alt="flyer" />
						</a>
					</div>:<div></div>}
				</div>
			);
		
	}
};

class ListadoDeActividades extends Component {
	constructor(props) {
		super(props);
		
		var initFiltros = {
			disciplina: "",
			centro: "",
			comuna: "",
			barrio: "",
			modalidad: "",
			taller: "",
			edadMin:"",
			edadMax:"",
			arancel: false,
			esAbierta: false
		}

		if (this.props.searchParams) {
			initFiltros = $.extend(initFiltros, this.props.searchParams);
		}

		this.state = {
			actividades: [],
			filtros: initFiltros,
		};

		this._handleSearch = this._handleSearch.bind(this);
		this._handleCleanFilters = this._handleCleanFilters.bind(this);		
		this.handleProvinceChange = this.handleProvinceChange.bind(this);
		this.handleDisciplineChange = this.handleDisciplineChange.bind(this);
		this.handleCommuneChange = this.handleCommuneChange.bind(this);
		this.handleNeighborhoodChange = this.handleNeighborhoodChange.bind(this);
		this.handleCenterChange = this.handleCenterChange.bind(this);
		this.handleModalityChange = this.handleModalityChange.bind(this);
		this.handleEdadMinChange = this.handleEdadMinChange.bind(this);
		this.handleEdadMaxChange = this.handleEdadMaxChange.bind(this);
		this.handleisOpenChange = this.handleisOpenChange.bind(this);
		this.handleisDisponibleChange = this.handleisDisponibleChange.bind(this);//izaregon947
		this.handleisPayChange = this.handleisPayChange.bind(this);

		this.onFilterChange = this.onFilterChange.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		var paramsHaveChanged;
		if (prevProps.searchParams && this.props.searchParams) {
			paramsHaveChanged = Object.keys(prevProps.searchParams)
				.some(
					key => prevProps.searchParams[key] != this.props.searchParams[key] //*ecarpiod
				);
		}
		if (paramsHaveChanged || (this.props.searchParams && !prevProps.searchParams)) {
			var filtros = $.extend({}, this.state.filtros);
			this.setState({
				filtros: $.extend(filtros, this.props.searchParams)
			});
		}
		this.onFilterChange();
	}

	_handleCleanFilters() {
		var oState = $.extend({}, this.state);
		oState.filtros = {
			disciplina: "",
			provincia: "",
			centro: "",
			comuna: "",
			barrio: "",
			modalidad: "",
			taller: "",
			edadMin:"",
			edadMax:"",
			esAbierta: false,
			arancel: false
		};
		this.setState(oState);
	}
	
	handleDisciplineChange(oEvent) {
		var filtros = $.extend({}, this.state.filtros);
		filtros.disciplina = oEvent.target.value;
		this.setState({
			filtros: filtros,
		});
		// this.onFilterChange();
	}
	async handleProvinceChange(oEvent) {
		var filtros = $.extend({}, this.state.filtros);
		filtros.provincia = oEvent.target.value;
		var idProvincia = oEvent.target.value;
		filtros.centro = '';
		if(idProvincia==="1"){
			filtros.barrio = "55";			//EZEIZA
		}
		this.setState({
			filtros: filtros,
		});
	

		this.baseQueryData = {
			$format: 'json',			
			$select: 'id,descripcion',
			$orderby: 'descripcion'
		}
		if(idProvincia && idProvincia!==0){
			this.baseQueryData["$filter"]= 'idProvincia eq '+idProvincia;
		}
		var barrios=[];
		var _this=this;
		 function fetchBarrios(queryData) {
			$.ajax({
				url: SERVICE_URL_DOMAIN + "/MasterService.xsodata/Barrio",
				data: queryData,
				dataType: 'json',
			}).done(
				function(data) {
					if (data && data.d && data.d.results) {
						barrios= data.d.results;
						var newDiv=<ComboBarrioGenerico
						barrios={barrios}
						seleccionProvincia={_this.state.filtros.provincia}
						seleccionada={_this.state.filtros.barrio}
						onChange={_this.handleNeighborhoodChange} />;

						ReactDOM.render(
							newDiv,
							document.getElementById('filter-Barrio')
						)
					}
				}.bind(this)
			);
		}
			await fetchBarrios(this.baseQueryData);

		
	}
	
	handleCommuneChange(oEvent) {
		var filtros = $.extend({}, this.state.filtros);
		filtros.comuna = oEvent.target.value;
		this.setState({
			filtros: filtros,
		});
		// this.onFilterChange();
	}
	
	handleNeighborhoodChange(oEvent) {
		var filtros = $.extend({}, this.state.filtros);
		filtros.barrio = oEvent.target.value;
		filtros.centro = '';
		this.setState({
			filtros: filtros,
		});
		// this.onFilterChange();
	}
	
	handleCenterChange(oEvent) {
		var filtros = $.extend({}, this.state.filtros);
		filtros.centro = oEvent.target.value;
		this.setState({
			filtros: filtros,
		});
		// this.onFilterChange();
	}
	
	handleModalityChange(oEvent) {
		var filtros = $.extend({}, this.state.filtros);
		filtros.modalidad = oEvent.target.value;
		this.setState({
			filtros: filtros,
		});
		// this.onFilterChange();
	}
	handleisDisponibleChange(oEvent) {//izaregon947
		var filtros = $.extend({}, this.state.filtros);
		filtros.esDisponible = oEvent.target.checked;
		this.setState({filtros: filtros});		
	}

	handleisOpenChange(oEvent) {
		var filtros = $.extend({}, this.state.filtros);
		filtros.esAbierta = oEvent.target.checked;
		this.setState({filtros: filtros});
		// this.onFilterChange();
	}

	handleEdadMinChange(oEvent) {
		var filtros = $.extend({}, this.state.filtros);
		filtros.edadMin = oEvent.target.value;
		this.setState({filtros: filtros});
		// this.onFilterChange();
	}

	handleEdadMaxChange(oEvent) {
		var filtros = $.extend({}, this.state.filtros);
		filtros.edadMax = oEvent.target.value;
		this.setState({filtros: filtros});
		// this.onFilterChange();
	}

	handleisPayChange(oEvent) {
		var filtros = $.extend({}, this.state.filtros);
		filtros.arancel = oEvent.target.checked;
		this.setState({filtros: filtros});
		// this.onFilterChange();
	}

	_handleSearch(oEvent, taller) {
		var filtros = $.extend({}, this.state.filtros);
		filtros.taller = taller;
		this.setState({filtros: filtros});
		// this.onFilterChange();
	}


	onFilterChange() {
		var oParams = {};
		Object.keys(this.state.filtros).forEach(
			key => {
				var f = this.state.filtros[key];
				if (!!f) {
					oParams[key] = f;
				}
			}
		);
		window.location.replace(`#/actividades/${this.props.reparticionID != 0 ? this.props.reparticionID : "buscar"}/?` + queryString.stringify(oParams));//*ecarpiod
		
	}

	render() {
		var filtrosCallbacks = {
			handleSearch: this._handleSearch,
			handleCleanFilters: this._handleCleanFilters,
			handleCenterChange: this.handleCenterChange,
			handleCommuneChange: this.handleCommuneChange,			
			handleProvinceChange: this.handleProvinceChange,
			handleDisciplineChange: this.handleDisciplineChange,
			handleisOpenChange: this.handleisOpenChange,
			handleisDisponibleChange: this.handleisDisponibleChange,//izaregon947
			handleisPayChange: this.handleisPayChange,
			handleEdadMinChange: this.handleEdadMinChange,
			handleEdadMaxChange: this.handleEdadMaxChange,
			handleModalityChange: this.handleModalityChange,
			handleNeighborhoodChange: this.handleNeighborhoodChange
		};
		return (
			<div className="container">
				<ListadoDeActividadesFiltros
					filtros={this.state.filtros}
					callbacks={filtrosCallbacks}
					ministerioURL ={this.props.ministerioURL}
					reparticionID={this.props.reparticionID} />
				<ListadoDeActividadesVista
					reparticionID={this.props.reparticionID}
					loadingStateCounter={this.props.loadingStateCounter}
					filtros={this.state.filtros}
					callbacks={filtrosCallbacks} />
			</div>
		);
	}
};



class BreadcrumbReparticion extends Component {
    render() {
        return (
            <Breadcrumb descripcion={this.props.reparticion.descripcion}>
				<BreadcrumbLinkLanding />
				<BreadcrumbLinkMinisterio ministerio={this.props.reparticion.Ministerio} />
            </Breadcrumb>
        )
    }
}

class JumbotronReparticion extends Component {
	render() {
		return (
			<Jumbotron
				banner={this.props.reparticion.banner}
				titulo={this.props.reparticion.descripcion}
				detalle={this.props.reparticion.detalle}>
				<BreadcrumbReparticion ministerio={this.props.reparticion.Ministerio} reparticion={this.props.reparticion} />
			</Jumbotron>
		);
	}
};

class JumbotronBusqueda extends Component {
	render() {
        return (
			<Jumbotron
				banner={this.props.banner}
				titulo={'Buscar'}
				detalle={'Todas las actividades'}>
				<Breadcrumb descripcion='Todas las actividades'>
					<BreadcrumbLinkLanding />
				</Breadcrumb>
			</Jumbotron>
		);
	}
}

export class Busqueda extends Component {
	constructor (props) {
		super(props);
		this.state = {
			reparticion: {},
			params: {}
		}

	}

	componentDidMount() {
		window.scrollTo(0,0);
		this.props.loadingStateCounter.increase();

		if (this.props.location.search) {
			var oParams = queryString.parse(this.props.location.search);
			this.setState({params: oParams});
		}

		$.ajax({
			url: SERVICE_URL_DOMAIN + "/MasterService.xsodata/Reparticion(0)",
			data: {
				$format: 'json',
				$select: 'banner'
			},
			dataType: 'json',
			cache: false
		})
		.done(
			function (data) {
				if (data && data.d) {
					this.setState({reparticion: data.d});
				}
			}.bind(this)
		)
		.fail(
			function(jqXHR, textStatus, errorThrown) {
				console.log("Error", jqXHR, textStatus, errorThrown );
				alert( "An error occurred while retrieving data from the server: " + textStatus );
			}
		)
		.always(this.props.loadingStateCounter.decrease);
	}

	render() {

		return(
			<div class="container">
				<JumbotronBusqueda banner={this.state.reparticion.banner} />
				<ListadoDeActividades
					loadingStateCounter={this.props.loadingStateCounter}
					searchParams={this.state.params} />
			</div>
		);
	}
}

export class Reparticion extends Component {
	constructor(props) {
		super(props);
		this.state = {
			reparticion: {Ministerio:{}},
			actividades: [],
			params: {}
		};
	}

	componentDidMount() {
		window.scrollTo(0,0);

		/* var reparticion = 0;
		if (this.props.match.params.reparticionId) {
			reparticion = (this.props.match.params.reparticionId);
		} */
		function redirect() {
			window.location.replace('/');
		}
		var reparticion = this.props.reparticion;

		if (this.props.location.search) {
			var oParams = queryString.parse(this.props.location.search);
			this.setState({params: oParams});
		}

		this.props.loadingStateCounter.increase();

		$.ajax({
			url: SERVICE_URL_DOMAIN + "/MasterService.xsodata/Reparticion(" + reparticion + ")",
			data: {
				$format: 'json',
				$expand: 'Ministerio',
				$select: 'id,banner,descripcion,detalle,Ministerio/descripcion,Ministerio/url'
			},
			dataType: 'json',
			cache: false
		})
		.done(
			function (data) {
				if (data && data.d) {
					this.setState({reparticion: data.d});
				}
				this.props.loadingStateCounter.decrease();
			}.bind(this)
		)
		.fail(
			function(jqXHR, textStatus, errorThrown) {
				console.log("Error", jqXHR, textStatus, errorThrown );
				// alert( "An error occurred while retrieving data from the server: " + textStatus );
				redirect(this.props);
			}
		);
	}

	render() {
		if (!this.state.reparticion.id) {
			return null;
		} else {
			return (
				<div className="container">
				<PopUp ministerio={this.state.reparticion} reparticionID={this.state.reparticion.id} /> 
				<JumbotronReparticion reparticion={this.state.reparticion}/>
					<ListadoDeActividades
						loadingStateCounter={this.props.loadingStateCounter}
						ministerioURL={this.state.reparticion.Ministerio.url}
						reparticionID={this.state.reparticion.id}
						searchParams={this.state.params} />
				</div>
			);
		}
	}
};
