import React, { Component } from 'react';

export const GCBA_URL_PREFIX = "http://www.buenosaires.gob.ar";

class TOP_HEADER_NAV_BSAS extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render () {
		return (
			<ul className="nav navbar-nav navbar-right navbar-buenosaires secondary">
				<li className="dropdown">
					<a href="node/58540" className="dropdown-toggle" data-toggle="dropdown">
						Trámites
						<div className="underline hidden-xs"></div>
					</a>
					<ul className="dropdown-menu main-menu">
						<li><a title="" href={GCBA_URL_PREFIX + "/consulta-de-infracciones"} target="_blank" rel="noopener noreferrer">Consulta de infracciones</a></li>
						<li><a title="Solicitá tu turno" href={GCBA_URL_PREFIX + "/tramites/turnos/vtv"} target="_blank" rel="noopener noreferrer">Verificación Técnica Vehicular</a></li>
						<li><a title="" href={GCBA_URL_PREFIX + "/tramites"} target="_blank" rel="noopener noreferrer">Ver todos los trámites</a></li>
						<li><a title="Ciudad del Papa" href={GCBA_URL_PREFIX + "/turnos"} target="_blank" rel="noopener noreferrer">Ver todos los turnos</a></li>
					</ul>
				</li>
				<li className="dropdown">
					<a href="http://solicitudes.buenosaires.gob.ar/suaci/index" className="dropdown-toggle" data-toggle="dropdown">
						Solicitudes
						<div className="underline hidden-xs"></div>
					</a>
					<ul className="dropdown-menu main-menu">
						<li><a title="" target="_blank" rel="noopener noreferrer" href="http://solicitudes.buenosaires.gob.ar/suaci/contactoPrestacion?codigoPrestacion=154018">Veredas rotas</a></li>
						<li><a title="" target="_blank" rel="noopener noreferrer" href="http://solicitudes.buenosaires.gob.ar/suaci/contactoPrestacion?codigoPrestacion=1462821340520">Retiro de escombros</a></li>
						<li><a title="" target="_blank" rel="noopener noreferrer" href="http://solicitudes.buenosaires.gob.ar/suaci/contactoPrestacion?codigoPrestacion=1462822772075">Retiro de residuos voluminosos</a></li>
						<li><a title="" target="_blank" rel="noopener noreferrer" href="http://solicitudes.buenosaires.gob.ar/suaci/contactoPrestacion?codigoPrestacion=167004">Reparación de luminaria</a></li>
						<li><a title="" target="_blank" rel="noopener noreferrer" href="http://solicitudes.buenosaires.gob.ar/suaci/contactoPrestacion?codigoPrestacion=1463604809212">Remoción de vehículo</a></li>
						<li><a title="" target="_blank" rel="noopener noreferrer" href="http://solicitudes.buenosaires.gob.ar/suaci/index">Ver todas las solicitudes</a></li>
					</ul>
				</li>
				<li className="dropdown">
					<a href="node/79649" className="dropdown-toggle" data-toggle="dropdown">
						Servicios de la Ciudad
						<div className="underline hidden-xs"></div>
					</a>
					<ul className="dropdown-menu main-menu">
						<li><a title="" target="_blank" rel="noopener noreferrer" href="http://disfrutemosba.buenosaires.gob.ar/">Disfrutemos BA</a></li>
						<li><a title="" target="_blank" rel="noopener noreferrer" href="https://turismo.buenosaires.gob.ar/es">Turismo</a></li>
						<li><a title="" target="_blank" rel="noopener noreferrer" href="https://trabajo.buenosaires.gob.ar/">Encontrá trabajo</a></li>
						<li><a title="" target="_blank" rel="noopener noreferrer" href="/subte">Subte</a></li>
						<li><a title="" target="_blank" rel="noopener noreferrer" href={GCBA_URL_PREFIX + "/ecobici"}>Ecobici</a></li>
						<li><a title="" target="_blank" rel="noopener noreferrer" href="/servicios">Ver todos los servicios</a></li>
					</ul>
				</li>
				<li className="dropdown">
					<a href="node/79280" className="dropdown-toggle" data-toggle="dropdown">
						Áreas de Gobierno
						<div className="underline hidden-xs"></div>
					</a>
					<ul className="dropdown-menu main-menu">
						<li><a title="" target="_blank" rel="noopener noreferrer" href={GCBA_URL_PREFIX + "/jefedegobierno"}>Jefatura de Gobierno</a></li>
						<li><a title="" target="_blank" rel="noopener noreferrer" href={GCBA_URL_PREFIX + "/educacion"}>Educación e Innovación</a></li><li><a title="" href="/salud">Salud</a></li>
						<li><a title="" target="_blank" rel="noopener noreferrer" href={GCBA_URL_PREFIX + "/desarrollourbano"}>Desarrollo Urbano y Transporte</a></li><li><a title="" href="/cultura">Cultura</a></li>
						<li><a title="" target="_blank" rel="noopener noreferrer" href={GCBA_URL_PREFIX + "/areasdegobierno"}>Ver todas las áreas</a></li>
					</ul>
				</li>
			</ul>	
		);
	}
};

class TOP_HEADER_NAV_ACCESIBILIDAD extends Component {
	render () {
		return (
			<ul className="nav navbar-nav navbar-accesible navbar-right primary">
				<li className=""><a target="_blank" rel="noopener noreferrer" href="http://www.buenosaires.gob.ar/transparencia" title="">Transparencia</a></li>
				<li className=""><a target="_blank" rel="noopener noreferrer" href="http://www.turismo.buenosaires.gob.ar/es" title="">Turismo</a></li>
				<li className=""><a target="_blank" rel="noopener noreferrer" href="http://www.buenosaires.gob.ar/noticias" title="">Noticias</a></li>
				<li className=""><a target="_blank" rel="noopener noreferrer" href="http://www.buenosaires.gob.ar/accesible" title="">Accesible</a></li>
				<li className=""><a target="_blank" rel="noopener noreferrer" href="https://login.buenosaires.gob.ar/" title="">Ingresar a miBA</a></li>
			</ul>
		);
	}
};

class TOP_HEADER extends Component {
	render () {
		return (
			<div className="navbar-accesible bg-navbar-accesible">
				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12 col-xs-12 navbar-header">
							<button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-accesible-id">
								<span className="icon-menu"></span>
								<span className="icon-close" style={{display: 'none'}}></span>
							</button>
						</div>
						<nav id="navbar-accesible-id" className="collapse navbar-collapse" role="navigation">
							<div className="container">
								<TOP_HEADER_NAV_BSAS />
								<TOP_HEADER_NAV_ACCESIBILIDAD />
							</div>
						</nav>
					</div>
				</div>
			</div>
		);
	}
};

class BOTTOM_HEADER_SEARCHBAR extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isOpen0: false,
			isOpen1: false,
			isOpen2: false,
			isOpen3: false,
		};
		this.toggleOpen0 = this.toggleOpen0.bind(this);
		this.toggleOpen1 = this.toggleOpen1.bind(this);
		this.toggleOpen2 = this.toggleOpen2.bind(this);
		this.toggleOpen3 = this.toggleOpen3.bind(this);
	}
	
	toggleOpen0() { this.setState({ isOpen0: !this.state.isOpen0, isOpen1: false, isOpen2: false, isOpen3: false}); }
	toggleOpen1() { this.setState({ isOpen0: false, isOpen1: !this.state.isOpen1, isOpen2: false, isOpen3: false}); }
	toggleOpen2() { this.setState({ isOpen0: false, isOpen1: false, isOpen2: !this.state.isOpen2, isOpen3: false}); }
	toggleOpen3() { this.setState({ isOpen0: false, isOpen1: false, isOpen2: false, isOpen3: !this.state.isOpen3}); }

	render() {
		const menuClass = [
							`dropdown-menu${this.state.isOpen0 ? " show" : ""}`,
							`dropdown-menu${this.state.isOpen1 ? " show" : ""}`,
							`dropdown-menu${this.state.isOpen2 ? " show" : ""}`,
							`dropdown-menu${this.state.isOpen3 ? " show" : ""}`
							];
		return (
			<div id="container-search-navbar" className="clearfix hidden-sm hidden-xs">
				<div id="navbar-principal" className="col-md-8 pull-right">
					<ul className="nav navbar-nav navbar-right navbar-buenosaires secondary">
						<li className="dropdown" onClick={this.toggleOpen0}>
							<a className="dropdown-toggle" id="ddmb0" data-toggle="dropdown" aria-haspopup="true">
								Trámites
								<div className="underline hidden-xs"></div>
							</a>
							<ul className={menuClass[0]} aria-labelledby="ddmb0">
								<li><a target="_blank" rel="noopener noreferrer" title="" href={GCBA_URL_PREFIX + "/consulta-de-infracciones"}>Consulta de infracciones</a></li>
								<li><a target="_blank" rel="noopener noreferrer" title="Solicitá tu turno" href={GCBA_URL_PREFIX + "/tramites/turnos/vtv"}>Verificación Técnica Vehicular</a></li>
								<li><a target="_blank" rel="noopener noreferrer" title="" href={GCBA_URL_PREFIX + "/tramites"}>Ver todos los trámites</a></li>
								<li><a target="_blank" rel="noopener noreferrer" title="Ciudad del Papa" href={GCBA_URL_PREFIX + "/turnos"}>Ver todos los turnos</a></li>
							</ul>
						</li>
						<li className="dropdown" onClick={this.toggleOpen1}>
							<a className="dropdown-toggle" id="ddmb1" data-toggle="dropdown">
								Solicitudes
	  							<div className="underline hidden-xs"></div>
							</a>
							<ul className={menuClass[1]} aria-labelledby="ddmb1">
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href="http://solicitudes.buenosaires.gob.ar/suaci/contactoPrestacion?codigoPrestacion=154018">Veredas rotas</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href="http://solicitudes.buenosaires.gob.ar/suaci/contactoPrestacion?codigoPrestacion=1462821340520">Retiro de escombros</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href="http://solicitudes.buenosaires.gob.ar/suaci/contactoPrestacion?codigoPrestacion=1462822772075">Retiro de residuos voluminosos</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href="http://solicitudes.buenosaires.gob.ar/suaci/contactoPrestacion?codigoPrestacion=167004">Reparación de luminaria</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href="http://solicitudes.buenosaires.gob.ar/suaci/contactoPrestacion?codigoPrestacion=1463604809212">Remoción de vehículo</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href="http://solicitudes.buenosaires.gob.ar/suaci/index">Ver todas las solicitudes</a></li>
							</ul>
						</li>
						<li className="dropdown" onClick={this.toggleOpen2}>
							<a className="dropdown-toggle" id="ddmb2" data-toggle="dropdown">
								Servicios de la Ciudad
	  							<div className="underline hidden-xs"></div>
							</a>
							<ul className={menuClass[2]} aria-labelledby="ddmb2">
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href="http://disfrutemosba.buenosaires.gob.ar/">Disfrutemos BA</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href="https://turismo.buenosaires.gob.ar/es">Turismo</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href="https://trabajo.buenosaires.gob.ar/">Encontrá trabajo</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href={GCBA_URL_PREFIX + "/subte"}>Subte</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href={GCBA_URL_PREFIX + "/ecobici"}>Ecobici</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href={GCBA_URL_PREFIX + "/servicios"}>Ver todos los servicios</a></li>
							</ul>
						</li>
						<li className="dropdown" onClick={this.toggleOpen3}>
							<a className="dropdown-toggle" id="ddmb3" data-toggle="dropdown">
								Áreas de Gobierno
  								<div className="underline hidden-xs"></div>
							</a>
							<ul className={menuClass[3]} aria-labelledby="ddmb3">
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href={GCBA_URL_PREFIX + "/jefedegobierno"}>Jefatura de Gobierno</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href={GCBA_URL_PREFIX + "/educacion"}>Educación e Innovación</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href={GCBA_URL_PREFIX + "/salud"}>Salud</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href={GCBA_URL_PREFIX + "/desarrollourbano"}>Desarrollo Urbano y Transporte</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href={GCBA_URL_PREFIX + "/cultura"}>Cultura</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href={GCBA_URL_PREFIX + "/areasdegobierno"}>Ver todas las áreas</a></li>
							</ul>
						</li>
					</ul>
				</div>
		 	</div>
		);
	  }

	render2 () {
		const menuClass = `dropdown-menu${this.state.isOpen ? " show" : ""}`;
		return (
			<div id="container-search-navbar" className="clearfix hidden-sm hidden-xs">
				<div id="navbar-principal" className="col-md-8 pull-right">
					<ul className="nav navbar-nav navbar-right navbar-buenosaires secondary">
						<li className="dropdown" onClick={this.toggleOpen}>
							<a className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true">
								Trámites
	  							<div className="underline hidden-xs"></div>
							</a>
							<ul className="dropdown-menu main-menu">
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href={GCBA_URL_PREFIX + "/consulta-de-infracciones"}>Consulta de infracciones</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="Solicitá tu turno" href={GCBA_URL_PREFIX + "/tramites/turnos/vtv"}>Verificación Técnica Vehicular</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href={GCBA_URL_PREFIX + "/tramites"}>Ver todos los trámites</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="Ciudad del Papa" href={GCBA_URL_PREFIX + "/turnos"}>Ver todos los turnos</a></li>
							</ul>
						</li>
						<li className="dropdown">
							<a href="http://solicitudes.buenosaires.gob.ar/suaci/index" className="dropdown-toggle" data-toggle="dropdown">
								Solicitudes
	  							<div className="underline hidden-xs"></div>
							</a>
							<ul className="dropdown-menu main-menu">
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href="http://solicitudes.buenosaires.gob.ar/suaci/contactoPrestacion?codigoPrestacion=154018">Veredas rotas</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href="http://solicitudes.buenosaires.gob.ar/suaci/contactoPrestacion?codigoPrestacion=1462821340520">Retiro de escombros</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href="http://solicitudes.buenosaires.gob.ar/suaci/contactoPrestacion?codigoPrestacion=1462822772075">Retiro de residuos voluminosos</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href="http://solicitudes.buenosaires.gob.ar/suaci/contactoPrestacion?codigoPrestacion=167004">Reparación de luminaria</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href="http://solicitudes.buenosaires.gob.ar/suaci/contactoPrestacion?codigoPrestacion=1463604809212">Remoción de vehículo</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href="http://solicitudes.buenosaires.gob.ar/suaci/index">Ver todas las solicitudes</a></li>
							</ul>
						</li>
						<li className="dropdown">
							<a href="node/79649" className="dropdown-toggle" data-toggle="dropdown">
								Servicios de la Ciudad
	  							<div className="underline hidden-xs"></div>
							</a>
							<ul className="dropdown-menu main-menu">
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href="http://disfrutemosba.buenosaires.gob.ar/">Disfrutemos BA</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href="https://turismo.buenosaires.gob.ar/es">Turismo</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href="https://trabajo.buenosaires.gob.ar/">Encontrá trabajo</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href={GCBA_URL_PREFIX + "/subte"}>Subte</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href={GCBA_URL_PREFIX + "/ecobici"}>Ecobici</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href={GCBA_URL_PREFIX + "/servicios"}>Ver todos los servicios</a></li>
							</ul>
						</li>
						<li className="dropdown">
							<a href="node/79280" className="dropdown-toggle" data-toggle="dropdown">
								Áreas de Gobierno
  								<div className="underline hidden-xs"></div>
							</a>
							<ul className="dropdown-menu main-menu">
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href={GCBA_URL_PREFIX + "/jefedegobierno"}>Jefatura de Gobierno</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href={GCBA_URL_PREFIX + "/educacion"}>Educación e Innovación</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href={GCBA_URL_PREFIX + "/salud"}>Salud</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href={GCBA_URL_PREFIX + "/desarrollourbano"}>Desarrollo Urbano y Transporte</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href={GCBA_URL_PREFIX + "/cultura"}>Cultura</a></li>
	  							<li><a target="_blank" rel="noopener noreferrer" title="" href={GCBA_URL_PREFIX + "/areasdegobierno"}>Ver todas las áreas</a></li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
		);
	}
};

class BOTTOM_HEADER_SEARCHMAIN_NAVBAR extends Component {
	render () {
		return (
			<div id="searchMainNavbar" className="hidden-lg hidden-md col-sm-2 col-xs-2 pull-right noPadding">
				<button href="#" className="btn btn-primary noPaddingRight">
					<span className="glyphicon glyphicon-search"></span>
				</button>
			</div>
		);		
	}
};

class BOTTOM_HEADER_SEARCHMAIN_LOGO extends Component {
	render () {
		return (
			<div className="col-md-3 col-sm-5 col-xs-6 logoBABAC pull-left">
				<a target="_blank" rel="noopener noreferrer" href="http://www.buenosaires.gob.ar/">
					<img src="/img/BA2016.png" />
				</a>
			</div>	
		);		
	}
};
class BOTTOM_HEADER extends Component {
	render () {
		return (
			<div className="container">
				<div className="row">
					<div className="col-md-12 col-sm-12 col-xs-12 text-right mainNavbar noPadding">
						<nav id="navbar-principal-id" className="navbar">
							<div className="row">
								<div id="container-search-navbar-global" className="clearfix col-md-12">
									<BOTTOM_HEADER_SEARCHMAIN_NAVBAR />
									<BOTTOM_HEADER_SEARCHMAIN_LOGO />
									<BOTTOM_HEADER_SEARCHBAR />
								</div>
							</div>
						</nav>
					</div>
				</div>
			</div>
		);
	}
};

class Header extends Component {
	render () {
		return (
			<header id="header" className="navbar navbar-primary navbar-top">
				<TOP_HEADER />
				<BOTTOM_HEADER />
			</header>
		);
	}
}

export default Header;