import React, { Component,Suspense } from 'react';
import Header from './components/header.js';
import Body from './components/body.js';
import Footer from './components/footer.js';

import LoadingOverlay from 'react-loading-overlay';

// import './styles/BAStyles.css';
// import './styles/BAstrap/bastrap.css';

class App extends Component {
  constructor(props) {
		super(props);
		this.showOverlay = this.showOverlay.bind(this);
		this.hideOverlay = this.hideOverlay.bind(this);
		this.state = {
      ajaxCount: 0,
			overlayIsActive: false
    };
    this.ajaxCount = 0;
	}
	showOverlay() {
		this.setState({overlayIsActive: true});
	}
	hideOverlay() {
		this.setState({overlayIsActive: false});
  }

  increaseAjaxCallsCount() {
    // this.setState({ajaxCount: this.state.ajaxCount + 1});
    this.ajaxCount = this.ajaxCount + 1;
    this.forceUpdate();
	}
	
	decreaseAjaxCallsCount() {
    /* if (this.state.ajaxCount > 0)
      this.setState({ajaxCount: this.state.ajaxCount - 1}); */
      if (this.ajaxCount > 0) {
        this.ajaxCount = this.ajaxCount - 1;
        this.forceUpdate();
      }
	}
  
  /* componentDidUpdate(prevProps, prevState) {
		if (prevState.ajaxCount != this.state.ajaxCount) {
			if (this.state.ajaxCount > 0) {
				this.showOverlay();
			} else {
				this.hideOverlay();
			}
		}
	} */

  render() {
    return (
      <Suspense  fallback={<Loading />}>
        <div className="App">
          <div>
          <LoadingOverlay
            active={ this.ajaxCount > 0 || this.state.overlayIsActive }
            spinner
            text='cargando...'>
            <Header />
            {/* <Body showOverlay={this.showOverlay.bind(this)} hideOverlay={this.hideOverlay.bind(this)}/> */}
            <Body loadingStateCounter={{
                increase: this.increaseAjaxCallsCount.bind(this),
                decrease: this.decreaseAjaxCallsCount.bind(this)
              }}/>
            <Footer version={this.props.version}/>
            </LoadingOverlay>
          </div>
        </div>
        </Suspense>
    );
  }
}
function Loading() {
  return <h2>🌀 Loading...</h2>;
}
export default App;