import React, { Component } from 'react';
import { withRouter } from "react-router";
import ReactDOM from 'react-dom';//+ecarpiod
import $ from 'jquery';
import { Breadcrumb, BreadcrumbLinkReparticion, BreadcrumbLinkMinisterio, BreadcrumbLinkLanding } from './heading-components';
import { ActivityDetailInformation } from './activity-detail';
import moment from "moment";
import MapaInteractivo from '@usig-gcba/mapa-interactivo';
import {
	Facebook,
	Twitter,
	Whatsapp,
	Mail,
} from 'react-social-sharing';
import Popup from "reactjs-popup";
export const SERVICE_URL_DOMAIN = "/destinations/GCBA_IOGEN_PU";
export const USIG_REST="/destinations/USIG_REST";//+ecarpiod

export const oEmptyActivity = {
	idTaller: "-1",
	idEncuentro: "-1",
	titulo: "",
	descripcion: "",
	imagen: "",
	dictadoPor: "",
	detalle: "",
	subtitulo: "",
	dias: "",
	Formulario: {
		Seccion: {
			results: []
		}
	}
};

export const BreadcrumbLinkBaires = withRouter(({ history }) => (
	<a className="clickable-link" onClick={() => { history.push('/') }}>Buenos Aires</a>
));


class SocialShareComponent extends Component {
	render() {
		return (
			<div className="row">
				<h3>Compartí esta actividad</h3>
				<div>
					<Facebook link={this.props.link} />
					<Twitter link={this.props.link} />
					<Whatsapp link={this.props.link} />
					<Mail link={this.props.link} />
					{/* <FacebookShareButton url={this.props.link}>
						<FacebookIcon size={this.props.iconSize} />
					</FacebookShareButton>
					<TwitterShareButton url={this.props.link}>
						<TwitterIcon size={this.props.iconSize} />
					</TwitterShareButton>
					<WhatsappShareButton url={this.props.link}>
						<WhatsappIcon size={this.props.iconSize} />
					</WhatsappShareButton>
					<EmailShareButton url={this.props.link}>
						<EmailIcon size={this.props.iconSize} />
					</EmailShareButton> */}
					{ /* <CopyToClipboard link={this.state.link} /> */ }
				</div>
			</div>
		);
	}
}


export class ActivityDetailDescription extends Component {
	
	
	// clicked = () => {
	// 	this.props.onLogin;
    //     this.setState({showPopup: true});
    //   }
	render() {
		const actividad = this.props.objActividad;
		// var isOpenInscription = actividad.inscripcionAbierta;
		var isOpenInscription = (actividad.estadoEncuentro==="1")?0:actividad.inscripcionAbierta;//+izaregon960
		
		// actividad.text_inscripcion //izaregon510 0:Inscribite 1:Asociate
		
		//izaregon683{			
		let popup = null;
		if(this.props.procesoInscripcion.validate===0) {
			// closeOnDocumentClick no permite que se cierre el popup si se hace clic fuera de este
		  popup = (
			<Popup open={this.props.procesoInscripcion.validate===0}
				closeOnDocumentClick={false} 						
				contentStyle={{ padding: "0px", border: "none", "max-width": "600px" }}
				modal >	
			 {/* <a style={{cursor:'pointer',position:'absolute',display:'block',padding:'2px 5px',lineHeight:'20px',right:'-10px',top:'-10px','font-size':'24px',background:' #ffffff','border-radius':'18px','border':'1px solid #cfcece','color':'#333333'}} 
				onClick={this.closeModal2}>&times;</a> */}			  
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLongTitle">Actividad sin cupos disponibles</h5>
					{/* <button onClick={this.closeModal} type="button" class="close" data-dismiss="modal" aria-label="Close" style={{'margin-top':'-23px'}}>
					<span aria-hidden="true">&times;</span>
					</button> */}
				</div>         
						<div class="modal-body">	
							   Todas la inscripciones que se realicen pasaran a estar en lista de espera. ¿Desea continuar?							
						</div>						
				<div class="modal-footer">
					<button type="button" onClick={this.props.viewFormsInscripction} class="btn btn-primary btnCrear">Continuar</button>
					<button onClick={this.props.deleteCuposInscripction} type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
				</div>
			</Popup>);
		} 
		//izaregon683{
		return (
			<div className="col-xs-12 col-sm-8">
				<div className="row">
						{/* izaregon601 */}
						<p style={{display:(actividad.vDisciplina===0)?"block" : "none"}	} ><b>{actividad.descripcionDisciplina}</b></p>
						<p>{actividad.nombreTaller}</p>
						{/* <p>{actividad.descripcionEncuentro}</p> */}
						
						<div className="content" dangerouslySetInnerHTML={{__html: actividad.descripcionEncuentro}}></div>{/* izaregon1100 */}
								{/* <div className="alert alert-info alert-dismissable" style={{display:(this.props.procesoInscripcion.validate===0)?"block" : "none"}	}>
									<h4>La cantidad de cupos solicitada no se puede procesar</h4>						
									<p>Puede ser que no existan los cupos solicitadis</p>
								</div> */}
									{/* izaregon599 */}		
									{popup}		{/* izaregon683 */}							
						<div class="form-group row" style={{display:(this.props.cargaMasiva===1)?"block" : "none"}	}>
							<label for="staticEmail" class="col-sm-7 col-form-label">Seleccione el número de inscripciones que desea realizar</label>
							<div class="col-sm-2">
								<select id={'idCountInscrip' }  className="form-control chosen-select"    data-placeholder="Elegí una opción" ref="form-field">
									<option value={1}>1</option>
									<option value={2}>2</option>
									<option value={3}>3</option>
									<option value={4}>4</option>
									<option value={5}>5</option>
								</select>
							</div>
						</div>
						<p>
							{ (isOpenInscription && actividad.urlPersonalizada.length>3) ?
							<button type="button" className={"btn btn-xl btn-primary "} onClick={() => { window.open(actividad.urlPersonalizada, '_blank').focus();}}>Ir al sitio de inscripción</button>					
							: null}

							{/* izaregon498 , 5 es la reparticion Biblioteca*/}
							{ (isOpenInscription && actividad.urlPersonalizada.length===0) ?
							<button type="button" className={"btn btn-xl btn-primary "} onClick={this.props.onLogin}>{ (actividad.text_inscripcion===1) ? 'Asociate':'Inscribite'} </button>
							: null}

							{/* { (isOpenInscription && actividad.Formulario) ?
							<button type="button" className={"btn btn-xl btn-primary "} onClick={this.props.onLogin}>Inscribite</button>
							: null} */}

							{ (!isOpenInscription) ?
							<button type="button" className={"btn btn-xl btn-default "}  >Inscripción Cerrada</button>
							: null}
							
						</p>
												
						<div  style={{display:(actividad.vPeriodo===1)?"none":"block"  }	}>{/* izaregon600 */}
							{/* izaregon557 */}
							<h3>{ (actividad.text_inscripcion===1) ? 'Período de Asociación':'Período de Inscripción'}</h3>
							{/* <h3>Período de Inscripción</h3> */}
							{actividad.fechaMinimaInscripcion} al {actividad.fechaMaximaInscripcion}
						</div>	
				</div>
				<SocialShareComponent link={window.location.href} iconSize={32} />
			</div>
		);
	}
};

export const ActivityDetailDescriptionWithRouter = withRouter(ActivityDetailDescription);

// class CopyToClipboard extends Component {
// 	constructor(props) {
// 		super(props);
// 		this.onclick = this.onclick.bind(this);
// 	}

// 	onclick(e) {
// 		const el = document.createElement('textarea');  // Create a <textarea> element
// 		el.value = this.props.link;                     // Set its value to the string that you want copied
// 		el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
// 		el.style.position = 'absolute';                 
// 		el.style.left = '-9999px';                      // Move outside the screen to make it invisible
// 		document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
// 		const selected =            
// 			document.getSelection().rangeCount > 0        // Check if there is any content selected previously
// 				? document.getSelection().getRangeAt(0)     // Store selection if found
// 				: false;                                    // Mark as false to know no selection existed before
// 		el.select();                                    // Select the <textarea> content
// 		document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
// 		document.body.removeChild(el);                  // Remove the <textarea> element
// 		if (selected) {                                 // If a selection existed before copying
// 			document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
// 			document.getSelection().addRange(selected);   // Restore the original selection
// 		}
// 	}

// 	render() {
// 		return (
// 		<a class="resp-sharing-button__link" href="javascript:void(0)" target="_blank" rel="noopener" aria-label="Copiar" onClick={this.onclick}>
// 			<div class="resp-sharing-button resp-sharing-button--medium"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--circle">
// 				{/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><circle cx="12" cy="12" r="11.5"/><path d="M15.84 9.5H13.5V8.48c0-.53.35-.65.6-.65h1.4v-2.3h-2.35c-2.3 0-2.65 1.7-2.65 2.8V9.5h-2v2h2v7h3v-7h2.1l.24-2z"/></svg> */}
// 				</div>
// 				Copiar link
// 			</div>
// 		</a>
// 		);
// 	}
// }
class MapaSede extends Component{//+ecarpiod APPSAPINO-315
	getCoordenadasGeograficas(coordenadas){
		return new Promise(function(resolve,reject){
			resolve({
				tipo_resultado:"Ok",
				lat:parseFloat(coordenadas.lat
					),
				lng:parseFloat(coordenadas.lng)
			});
			reject('Error coordenadas sede');
			
		});
		// return new Promise(function(resolve,reject){
		// 	$.ajax({
		// 		type:"get",
		// 		async:false,
		// 		dataType:'json',
		// 		url: USIG_REST+'/rest/convertir_coordenadas?x='+coordenadas.lat+'&y='+coordenadas.lng+'&output=lonlat',
		// 		success:function(data){
		// 			resolve({
		// 				tipo_resultado:data.tipo_resultado,
		// 				lat:parseFloat(data.resultado.y
		// 					),
		// 				lng:parseFloat(data.resultado.x)
		// 			});
		// 		},
		// 		error:function(xhr, textStatus, errorMessage){
		// 			reject(errorMessage);
		// 		}
		// 	});
		// });
	}
	getCoordenadasEncuentro(idEncuentro){
		return new Promise(function (resolve,reject){
			$.ajax({
				type:"get",
				async:false,
				dataType:'json',
				url: SERVICE_URL_DOMAIN + "/Taller.xsodata/Encuentro("+idEncuentro+"L)/Sede",
				success: function (data) {
					resolve({
						lat:data.d.latitud,
						lng:data.d.longitud,
						direccion:data.direccion
					});
				},
				error: function (xhr, textStatus, errorMessage) {
					reject(errorMessage);
				}
			});
		});
	}
	 redirect() {
		window.location.replace('/');
	}
	async componentDidMount(){
		try{
			var coordenadasUtm=await this.getCoordenadasEncuentro(this.props.actividad.idEncuentro);
			var coordendasGeograficas=await this.getCoordenadasGeograficas(coordenadasUtm);
			setTimeout(function(){
				if(coordendasGeograficas.tipo_resultado==="Ok"){
					var map=new MapaInteractivo("map", {center:[coordendasGeograficas.lat,coordendasGeograficas.lng],zoom:14});
					map.addMarker(coordendasGeograficas,true);
				}
			},2000);
		}catch(e){
			this.redirect(this.props);
			// alert('Error al obtener ubicación de la sede');
		}
	}
	render(){
		return (
			<div className="col-xs-12">
				<div id="map" style={{width: '100%',height: '250px',"margin-top":"10px"}}></div>
			</div>
		);
	}
};
export class ActivityDetailView extends Component {

	render() {
		return (
			<div className="col-xs-12 col-md-4">
				<div className="row">
					<div className="col-xs-12">
						<h2 className="col-xs-12 text-center">Información de la actividad</h2>
						<ActivityDetailInformation actividad={this.props.actividad} colSize={9} />
					</div>
				</div>
				<div className="row">
					<MapaSede actividad={this.props.actividad}/>
				</div>
			</div>
		);
	}
};

class ComboItem extends Component {
	render() {
		return (
			<option value={this.props.item}>{this.props.item}</option>
		);
	}
};

class Combo extends Component {
	render() {
		var aListItems = this.props.listItem.split(",");
		var aOptions = [
			<ComboItem key="" item="" />
		];
		aListItems.map((oItem) => aOptions.push(<ComboItem key={oItem} item={oItem} />));
		
		return (
			<select id={'field-' + this.props.fieldId} className="form-control chosen-select" data-tipoinput={this.tipoInput = this.props.tipoInput} data-adultomayor={this.datoAdultoMayor = this.props.datoAdultoMayor} onChange={this.props.onInputChange} data-placeholder="Elegí una opción" data-fieldid={this.id = this.props.fieldId} data-required={this.required = this.props.required} ref="form-field">
				{aOptions}
			</select>
		);
	}
};
export class ActivityDetailFormSectionFields extends Component {	
	_getInputControl(oCampo, bSeccionAdulto) {
		switch (oCampo.control) {
			case "input":
				return (
					<div>
						<input
							type="text" 
							className="form-control" 
							maxLength={this.props.campo.maximaLongitud ? this.props.campo.maximaLongitud : "30"} 
							id={'field-' + this.props.campo.id} 
							onChange={this.props.onInputChange} 
							data-fieldid={this.id = this.props.campo.id} 
							data-required={this.required = this.props.campo.required}
							data-adultomayor={bSeccionAdulto} 
							ref="form-field" />
						<span style={{ fontWeight: "bold", color: "red", visibility: "hidden" }} id={'field-' + this.props.campo.id + '-error'}></span>
					</div>
				);
				break;
			case "mail":
				return (
					<div>
						<input 
							type="email" 
							className="form-control" 
							maxLength={this.props.campo.maximaLongitud ? this.props.campo.maximaLongitud : "50"} 
							placeholder={this.props.campo.placeHolder} 
							id={'field-' + this.props.campo.id} 
							onChange={this.props.onInputChange} 
							data-fieldid={this.id = this.props.campo.id} 
							data-required={this.required = this.props.campo.required} 
							data-input-type-id={this.inputtypeid = this.props.campo["tipo.id"]}
							data-adultomayor={bSeccionAdulto} 
							ref="form-field" />
						<span style={{ fontWeight: "bold", color: "red", visibility: "hidden" }} id={'field-' + this.props.campo.id + '-error'}></span>
					</div>
				);
				break;
			case "number":
				var sMax = "";
				var iCant = parseInt(this.props.campo.maximaLongitud);
				iCant = isNaN(iCant) ? 10 : iCant;
				for (var i = 0; i < iCant; i++)
					sMax += "9";
				return (
					<div>
						<input
							type="text" 
							className="form-control" 
							maxLength={this.props.campo.maximaLongitud ? this.props.campo.maximaLongitud.toString() : "10"} 
							id={'field-' + this.props.campo.id} 
							onChange={this.props.onInputChange} 
							data-fieldid={this.id = this.props.campo.id} 
							data-required={this.required = this.props.campo.required} 
							data-tipoinput={this.tipoInput = this.props.campo["tipo.id"]} 
							data-adultomayor={bSeccionAdulto} 
							ref="form-field" />
						<span style={{ fontWeight: "bold", color: "red", visibility: "hidden" }} id={'field-' + this.props.campo.id + '-error'}></span>
					</div>
				);
				break;
			case "date":
				var today = new Date();
				var sMonth = ("00" + (today.getMonth() + 1).toString()).slice(-2);
				var sDay = ("00" + (today.getDate()).toString()).slice(-2);
				var sMax2 = today.getFullYear() + "-" + sMonth + "-" + sDay;
				return (
					<div>
						<input
							type="date" 
							className="form-control" 
							id={'field-' + this.props.campo.id} 
							onChange={(!bSeccionAdulto) ? this.props.onBirthdateChange : function () { }} 
							data-fieldid={this.id = this.props.campo.id} 
							data-required={this.required = this.props.campo.required}
							data-adultomayor={bSeccionAdulto} 
							min="1900-01-01" 
							max={sMax2} 
							ref="form-field" />
						<span style={{ fontWeight: "bold", color: "red", visibility: "hidden" }} id={'field-' + this.props.campo.id + '-error'}></span>
					</div>
				);
				break;
			case "combobox":
				return (
					<div>
						<Combo 
							fieldId={this.props.campo.id}
							listItem={this.props.campo.listItem}
							onInputChange={this.props.onInputChange}
							label={this.props.campo.label} 
							required={this.props.campo.required}
							datoAdultoMayor={bSeccionAdulto} 
							tipoInput={this.props.campo["tipo.id"]}
							ref="form-field" />
						<span style={{ fontWeight: "bold", color: "red", visibility: "hidden" }} id={'field-' + this.props.campo.id + '-error'}></span>
					</div>
				);
				break;
			case "checkbox":
				return (
					<div className="checkbox col-xs-12 col-sm-6 col-md-4">
						<input 
							type="checkbox" 
							id={'field-' + this.props.campo.id} 
							onChange={this.props.onInputChange} 
							data-fieldid={this.id = this.props.campo.id} 
							data-required={this.required = this.props.campo.required}
							data-adultomayor={bSeccionAdulto}  />
						<label htmlFor={'field-' + this.props.campo.id}>
							{this.props.campo.label}
						</label>
					</div>
				);
				break;
			// izaregon408
				case "FileUploader":					
					return (						
						<div>						
							<form onSubmit={this.handleSubmit}>
								<input type="file" 		  
								className="form-control" 
								maxLength={this.props.campo.maximaLongitud ? this.props.campo.maximaLongitud : "30"} 
								id={'field-' + this.props.campo.id} //izaregon476
								onChange={this.props.enviarImagen} //izaregon408: uliza la funcion en la base para setear la bse64 directamente en el formulario
								data-fieldid={this.id = this.props.campo.id} 
								data-required={this.required = this.props.campo.required}
								data-adultomayor={bSeccionAdulto} 
								ref="form-field"
								accept="image/*" href="input" 
								/>	
			{/* izaregon490 */}<button type="button" className="btn  btn-primary"  onClick={this.downloadModelo.bind(this,this.props.campo.modeloAdjunto,this.props.campo.label)}
								style={{float:"right", marginTop:"-34px",marginRight:"-42px",display:(this.props.campo.modeloAdjunto)?"block" : "none"	}}>
								<span className={`glyphicon glyphicon-download text-muted`}></span>
								</button>
      						</form>
							  {/* izaregon483 */}
							  <span style={{ fontWeight: "bold", color: "red", visibility: "hidden" }} id={'field-' + this.props.campo.id + '-error'}></span>
            			</div>
					);
					break;
				// izaregon408
			default:
				break;
		}

	}
	downloadModelo(modeloAdjunto,label){//izaregon490
		console.log(modeloAdjunto);
		var downloadLink = document.createElement("a");		   
		    downloadLink.href = modeloAdjunto;
		    downloadLink.download = label;
		    downloadLink.click();
	}
	_getLabelDecoratedField() {
		//izaregon588, 13: area deportes
		if(this.props.idministerio==="13" && this.props.campo.label==="Provincia" &&
		 this.props.campo.control==="combobox"){ 
			 this.props.campo.listItem='Ciudad de Buenos Aires'; 
		}//izaregon588,ministerio cultura
		var campoDecorado = this._getInputControl(this.props.campo, this.props.seccionAdulto);
		if (this.props.campo.control !== "checkbox") {
			campoDecorado = (
			<label htmlFor={'field-' + this.props.campo.id} style={{ display: "block" }} >{this.props.campo.label}{this.props.campo.required == 1 ? " *" : ""} {/* *ecarpiod */}
				{campoDecorado}
			</label>
			);
		}
		return campoDecorado;
	}

	render() {
		var esCheckbox = this.props.campo.control === "checkbox";
		var sDisplay = !this.props.seccionAdulto && this.props.inscritoEsMenor && this.props.campo.control === "mail" ? "none" : "block";
		return (//+ecarpiod
			<div className={"div-field-"+this.props.campo.id}>
				<div className={esCheckbox ? "form-group row" : "form-group"} style={{ display: sDisplay }}>
					{ this._getLabelDecoratedField() }
				</div>
			</div>

		);

	}
};

export class ActivityDetailFormSection extends Component {
	componentDidMount() {
		$('input[data-input-type-id="5"]').bind("cut copy paste", function (e) { e.preventDefault(); });
		$('input[data-input-type-id="7"]').bind("cut copy paste", function (e) { e.preventDefault(); });
	}

	render() {
		var oSeccion = this.props.seccion;
		var aCampos = oSeccion.Campo.results.sort((a, b) => a.orden - b.orden);		
		aCampos = this.props.seccion.Campo.results.map(function (oSeccion, oCampo) {
			return <ActivityDetailFormSectionFields
				campo={oCampo}
				idministerio={this.props.idministerio}//izaregon588
				key={oCampo.id}
				seccionAdulto={this.props.seccion.seccionAdultoResponsable}
				onBirthdateChange={this.props.onBirthdateChange}
				onInputChange={this.props.onInputChange}
				enviarImagen={this.props.enviarImagen}//izaregon408							
				inscritoEsMenor={this.props.inscritoEsMenor} />
		}.bind(this, oSeccion));

		return (
			<div style={{
				// display: (this.props.seccion.seccionAdultoResponsable === 0 ||
				// 	(this.props.seccion.seccionAdultoResponsable === 1 &&
				// 		this.props.inscritoEsMenor === true)) ? "block" : "none"
				//izaregon412{
				display: (this.props.seccion.seccionAdultoResponsable === 0 || 
					(this.props.adultoObligatorio===1 &&	this.props.seccion.seccionAdultoResponsable === 1	) ||
					(this.props.seccion.seccionAdultoResponsable === 1 &&
						this.props.inscritoEsMenor === true)) ? "block" : "none"
				//}izaregon412
			}} >
				{/* izaregon587 */}				
				<h2>{ (this.props.seccion.nombre==="Intereses") ? 'Datos Adicionales':this.props.seccion.nombre} </h2>
								
				{/* izaregon476 */}
				<p style={{display:(this.props.seccion.nombre==='Adjunto')?"block" : "none"}}
				>Los archivos permitidos son de formato jpg, png y pdf, con un maximo de 5mb</p>
				<h3 style={{display: (this.props.procesoInscripcion.lote >0 && this.props.seccion.nombre==="Seccion Datos Personales") ? 'block' : 'none'}}>{ (this.props.actividad.text_inscripcion===1) ? 'Asociación N° ':'Inscripción N° '} {this.props.procesoInscripcion.inscripcionesRealizadas+1}</h3>
					{aCampos}
				
			</div>
		);
	}
};
export class ActivityDetailFormMasivo extends Component {
	render() {
		
			return <ActivityDetailForm 
			procesoInscripcion={this.props.procesoInscripcion}//izaregon599
			listInscripciones={this.props.listInscripciones}	//izaregon599
				secciones={this.props.actividad.Formulario.Seccion.results}
				actividad={this.props.actividad}//izaregon512
				inscritoEsMenor={this.props.inscritoEsMenor}
				adultoObligatorio={this.props.adultoObligatorio}//izaregon412
				onBirthdateChange={this.props.onBirthdateChange}
				onInputChange={this.props.onInputChange}
				enviarImagen={this.props.enviarImagen}//izaregon408
				onSubmitForm={this.props.onSubmitForm}
				/* captchaHandler={this.props.captchaHandler} */
				captchaIDHandler={this.props.captchaIDHandler} />
	}
}
export class ActivityDetailForm extends Component {
	constructor (props) {
		super(props);
		this.fileInput = React.createRef();//izareon 408
		this.state = {
			display: "none"
		};
	}

	componentDidMount() {
		// var id = window.grecaptcha.render("form-recaptcha", {
		// 	siteKey: process.env.REACT_APP_GOOGLE_CAPTCHA_SITEKEY
		// });
		// this.props.captchaIDHandler(id);
		// if(this.props.procesoInscripcion.cuposDisponibles===0){//izaregon599
		// alert("No existen cupos disponibles, por lo que las inscripciones quedaran en lista de espera.");
		// }else{
		// 	if(this.props.procesoInscripcion.cuposDisponibles<this.props.procesoInscripcion.cuposAInscribirse){
		// 		alert("Solo existen "+this.props.procesoInscripcion.cuposDisponibles+" cupos disponibles. ");
		// 	}
		// }
	}

	render() {
		if(this.props.actividad.Formulario.cargaMasiva===1 && this.props.procesoInscripcion.inscripcionesRealizadas>0 && this.props.secciones[0].nombre==="Sección Adulto/Tutor Responsable"){
			// if(document.getElementById("cuerpoFormulario")!==null){document.getElementById("cuerpoFormulario").innerHTML="";}
			this.props.secciones.shift();
		}
		//izaregon1061{
		var terminosNull=( (this.props.actividad.Formulario.urlTerminos===null || this.props.actividad.Formulario.urlTerminos=='0')?0:1);//No se muestra
		var vLinkTerminos=(terminosNull===0?0:this.props.actividad.Formulario.urlTerminos.length);//En caso no sea nulo, se obtiene el numero de caracteres
		//}izaregon1061
		var aSecciones = this.props.secciones.map(
			function (oSeccion) {
				return (
					<ActivityDetailFormSection
						seccion={oSeccion}
						idministerio={this.props.actividad.Formulario["ministerio.id"]}//izaregon588
						key={oSeccion.id}
						procesoInscripcion={this.props.procesoInscripcion}//izaregon599
						actividad={this.props.actividad}//izaregon599
						inscritoEsMenor={this.props.inscritoEsMenor}
						adultoObligatorio={this.props.adultoObligatorio}//izaregon412
						onBirthdateChange={this.props.onBirthdateChange}
						enviarImagen={this.props.enviarImagen}//izaregon408
						onInputChange={this.props.onInputChange} />
				);
			}.bind(this)
		);
		return (
			<div className="col-xs-12 col-sm-8" id="cuerpoFormulario">
				{/* izaregon512 */}
				{/* this.state.procesoInscripcion.inscripcionesRealizadas+1 */}
				<h2>{ (this.props.actividad.text_inscripcion===1) ? 'Formulario de Asociación':'Formulario de inscripción'}</h2>	
				<p>Completá el siguiente formulario para { (this.props.actividad.text_inscripcion===1) ? 'asociarte a la actividad':'inscribirte a la actividad'}</p>
				{/* izaregon512 */}
				<div className="alert alert-info alert-dismissable">
					<button type="button" className="close" data-dismiss="alert" aria-hidden="true">&times;</button>
					<span className="glyphicon glyphicon-info-sign"></span>
					<p>Los datos ingresados tienen que ser de la persona que asistirá a la actividad. En caso de ser menor de edad, debe registrarse también un adulto responsable</p>
					<p><b>Los campos marcados con * son obligatorios</b></p>
				</div>
				{/* izaregon599 */}
				{/* <h3 style={{display: this.props.procesoInscripcion.lote >0 ? 'block' : 'none'}}>{ (this.props.actividad.text_inscripcion===1) ? 'Asociación N° ':'Inscripción N° '} {this.props.procesoInscripcion.inscripcionesRealizadas+1}</h3> */}

				<form className="col-xs-12">
					{aSecciones}
					<div style={{ clear: "both", marginTop: "25px" }}>
						<div className="col-xs-12" style={{ margin: "20px 0", padding: "0" }}>
							{/* izarego1061 */}
							
								<div className="checkbox" style={{display: (this.props.actividad.Formulario.urlTerminos!=null && this.props.actividad.Formulario.urlTerminos!='0') ? 'block' : 'none'}}>
									<input style={{ margin: "3px 0 0" }}
										type="checkbox" 
										id='ChTerminos' 
										/>
									<label style={{display: vLinkTerminos >1 ? 'block' : 'none'}}>
										Acepto <a style={{ color:'#428bca'}} href={this.props.actividad.Formulario.urlTerminos} className="clickable-link" target={"_blank"}>Términos y Condiciones</a>										 
									</label>

									<label style={{display: vLinkTerminos==1 ? 'block' : 'none'}}>
										Acepto Términos y Condiciones				 
									</label>
								</div>
							{/* izarego1061 */}
						{/* izaregon-captcha */}
							{/* <div id="form-recaptcha" className="g-recaptcha" data-sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITEKEY}></div>
							<div id="form-recaptcha-alert" style={{ display: "none" }} className="alert alert-danger">Confirmá que no sos un robot</div> */}
						{/* izaregon-captcha */}
						</div>
						<button type="button" className="btn btn-xl btn-primary" onClick={this.props.onSubmitForm}>Enviar</button> 
					</div>
				</form>
			</div>
		);
	}
};

/* class ActivityDetailAgeValidationForm extends Component {
	
	render() {
		var today = new Date();
		var sMonth = ("00" + (today.getMonth() + 1).toString()).slice(-2);
		var sDay = ("00" + (today.getDate()).toString()).slice(-2);
		var sMax = today.getFullYear() + "-" + sMonth + "-" + sDay;
		return (
			<div>
				<form role="form">
					<input
						type="date"
						className="form-control"
						onChange={this.props.onBirthdateChange}
						min="1900-01-01"
						max={sMax} />
					<button type="button" className="btn btn-xl btn-primary" onClick={this.props.checkBirthdate}>Comprobar</button>
				</form>
			</div>
		);
	}
} */

export class ActivityInscriptionStatus extends Component {
	constructor(props){
		super(props);
		this.state = { open: false };
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);		
	}
	componentDidMount() {
		window.scrollTo(0, 0);
		if(this.mostrarPopUp()){
			this.openModal();
		}
	}
	mostrarPopUp(){
		return this.obtenerEdad()>=15 && this.obtenerEdad()<=19;
	}
	obtenerEdad(){
		var ageDate=new Date(Date.now()-new Date(this.props.fechaNacimiento).getTime());
		return Math.abs(ageDate.getUTCFullYear()-1970);
	}
	openModal() {
		this.setState({ open: true });
	}
	closeModal() {
		this.setState({ open: false });
	}
	render() {
		var sSpotClass = "alert-spot";
		sSpotClass += this.props.inscripcionConfirmada === true ? " alert-spot-success" : " alert-spot-primary";

		var sIcon = "glyphicon";
		sIcon += this.props.inscripcionConfirmada === true ? " glyphicon-ok" : " glyphicon-warning-sign";
		{/* izaregon557 {*/}
		var mensajeInicio='';
		var mensajeConfirmacion='';
		if(this.props.procesoInscripcion.inscripcionesRealizadas<=1){
				if(this.props.inscripcionConfirmada === true ){
					if(this.props.actividad.text_inscripcion===1){//izaregon557, 0:Inscribite 1:Asociate
						mensajeInicio='¡Felicidades! Tu asociación fue confirmada.'; 
						mensajeConfirmacion='Tu código de asociación es: ' + this.props.codigoInscripcion + ', pronto vas a recibir ';
						mensajeConfirmacion+='tu credencial por email!';	
					}else{
						mensajeInicio='¡Felicidades! Tu inscripción fue confirmada.'; 
						mensajeConfirmacion='Tu código de inscripción es: ' + this.props.codigoInscripcion + ', pronto vas a recibir ';
						mensajeConfirmacion+='más información en tu email!';
					}
					
				}else{
					mensajeConfirmacion='Lamentablemente no quedan cupos en la actividad, pero quedaste en lista de espera! Tu código de ';
					if(this.props.actividad.text_inscripcion===1){
						mensajeInicio='Gracias por anotarte! Tu asociacion aún no fue confirmada.'; 
						mensajeConfirmacion+='asociación  es: ' + this.props.codigoInscripcion + '. Vas a recibir novedades sobre la asociación  en tu email.';
					}else{
						
						mensajeInicio='Gracias por anotarte! Tu inscripción aún no fue confirmada.'; 
						mensajeConfirmacion+='inscripción es: ' + this.props.codigoInscripcion + '. Vas a recibir novedades sobre la inscripción en tu email.';
					}
				}
		}else{
			var codigoInscripcion='';
			for (var i =0; i< this.props.listInscripciones.inscripcion.length;i++) {			 
				if(i ===0){
					codigoInscripcion=this.props.listInscripciones.inscripcion[i].codigoInscripcion;
				}else{
					codigoInscripcion=codigoInscripcion+', '+this.props.listInscripciones.inscripcion[i].codigoInscripcion;
				}
			}
			if(this.props.inscripcionConfirmada === true ){
				if(this.props.actividad.text_inscripcion===1){//izaregon557, 0:Inscribite 1:Asociate
					mensajeInicio='¡Felicidades! Las asociaciones fueron confirmadas.'; 
					mensajeConfirmacion='Los códigos de asociación son: ' + codigoInscripcion + ', pronto vas a recibir ';
					mensajeConfirmacion+='las credenciales por email!';	
				}else{
					mensajeInicio='¡Felicidades! Las inscripciones fueron confirmadas.'; 
					mensajeConfirmacion='los código de inscripción son: ' + codigoInscripcion + ', pronto vas a recibir ';
					mensajeConfirmacion+='más información en tu email!';
				}
				
			}else{
				mensajeConfirmacion='Lamentablemente no quedan cupos en la actividad, pero las inscripciones quedaron en lista de espera! Los códigos de ';
				if(this.props.actividad.text_inscripcion===1){
					mensajeInicio='Gracias por anotarte! Las asociaciones aún no fueon confirmadas.'; 
					mensajeConfirmacion+='asociación  son: ' + codigoInscripcion + '. Vas a recibir novedades sobre las asociaciones  en tu email.';
				}else{
					
					mensajeInicio='Gracias por anotarte! Las inscripciones aún no fue confirmadas.'; 
					mensajeConfirmacion+='inscripción son: ' + codigoInscripcion + '. Vas a recibir novedades sobre la inscripción en tu email.';
				}
			}
		}
		
		return (
			<div className="col-xs-12 col-sm-8 text-center">
				<Popup
					open={this.state.open}
					closeOnDocumentClick
					onClose={this.closeModal}
					contentStyle={{ padding: "0px", border: "none" ,"max-width":"600px"}}
					modal
				>
					<div >
						<a style={{cursor:'pointer',position:'absolute',display:'block',padding:'2px 5px',lineHeight:'20px',right:'-10px',top:'-10px','font-size':'24px',background:' #ffffff','border-radius':'18px','border':'1px solid #cfcece','color':'#333333'}} onClick={this.closeModal}>&times;</a>
						<a href={"https://disfrutemosba.pasecultural.buenosaires.gob.ar/#/inicio"} target={"_blank"}>
							<img src="/img/PaseCulturalPopup.jpg" alt=""></img>
						</a>
						
					</div>
				</Popup>
				<div className={sSpotClass}>
					<span className={sIcon}></span>
					<div className="alert-link-text">
					<h4>{mensajeInicio}</h4> {/* izaregon557 */}
						{/* <h4>{this.props.inscripcionConfirmada === true ? "Felicidades! Tu inscripción fue confirmada." : "Gracias por anotarte! Tu inscripción aún no fue confirmada."}</h4> */}
						{/* <p>{
							this.props.inscripcionConfirmada === true ?
								'Tu código de inscripción es: ' + this.props.codigoInscripcion + ', pronto vas a recibir más información en tu mail!' :
								'Lamentablemente no quedan cupos en la actividad, pero quedaste en lista de espera! Tu código de inscripción es: ' + this.props.codigoInscripcion + '. Vas a recibir novedades sobre la inscripción en tu mail.'
						}</p> */}
						<p>{mensajeConfirmacion}</p>{/* izaregon557 */}
					</div>
				</div>
				<a className="btn btn-large btn-primary" onClick={this.props.handleDownloadPdf}>
					<span className="glyphicon glyphicon-download-alt"></span>
					<p>{this.props.actividad.text_inscripcion===1 ? "Descargá tu comprobante" : "Descargá tu inscripción en pdf!"}</p>
					{/* <p>Descargá tu inscripción en pdf!</p> */}
				</a>

			</div>
		);
	}
};

export class ActivityDetail extends Component {	

	render() {
		const bIsMibaLogged = this.props.mibaLogueado;
		var content;
		if (bIsMibaLogged) {
			const bFormularioProcesado = this.props.formularioProcesado;			
				if (bFormularioProcesado) {
						content = (
							<ActivityInscriptionStatus
								actividad={this.props.actividad}//izaregon557
								procesoInscripcion={this.props.procesoInscripcion}//izaregon599
								listInscripciones={this.props.listInscripciones}	//izaregon599
								inscripcionConfirmada={this.props.inscripcionConfirmada}
								codigoInscripcion={this.props.codigoInscripcion}
								fechaNacimiento={this.props.fechaNacimiento}
								handleDownloadPdf={this.props.handleDownloadPdf} />
						);
				} else {					
					if(this.props.procesoInscripcion.secciones){ 
						content = (<ActivityDetailFormMasivo  procesoInscripcion={this.props.procesoInscripcion}//izaregon599
							listInscripciones={this.props.listInscripciones}	//izaregon599
							secciones={this.props.actividad.Formulario.Seccion.results}
							actividad={this.props.actividad}//izaregon512
							inscritoEsMenor={this.props.inscritoEsMenor}
							adultoObligatorio={this.props.adultoObligatorio}//izaregon412
							onBirthdateChange={this.props.onBirthdateChange}
							onInputChange={this.props.onInputChange}
							enviarImagen={this.props.enviarImagen}//izaregon408
							onSubmitForm={this.props.onSubmitForm}
							/* captchaHandler={this.props.captchaHandler} */
							captchaIDHandler={this.props.captchaIDHandler} />);}//izaregon599
					else{
						content = (
							<ActivityDetailForm 
							procesoInscripcion={this.props.procesoInscripcion}//izaregon599
							listInscripciones={this.props.listInscripciones}	//izaregon599
								secciones={this.props.actividad.Formulario.Seccion.results}
								actividad={this.props.actividad}//izaregon512
								inscritoEsMenor={this.props.inscritoEsMenor}
								adultoObligatorio={this.props.adultoObligatorio}//izaregon412
								onBirthdateChange={this.props.onBirthdateChange}
								onInputChange={this.props.onInputChange}
								enviarImagen={this.props.enviarImagen}//izaregon408
								onSubmitForm={this.props.onSubmitForm}
								/* captchaHandler={this.props.captchaHandler} */
								captchaIDHandler={this.props.captchaIDHandler} />
						);
					}
				}
			// }
		} else {
			content = (
				<ActivityDetailDescriptionWithRouter
				cargaMasiva={this.props.actividad.Formulario.cargaMasiva}//izaregon599
				procesoInscripcion={this.props.procesoInscripcion}//izaregon599
					objActividad={this.props.actividad}
					onLogin={this.props.onLogin} 
					viewFormsInscripction={this.props.viewFormsInscripction} //izaregon683
					deleteCuposInscripction={this.props.deleteCuposInscripction}//izaregon683	
					/>
			);
		}
		return (
			<div>
				{content}
				<ActivityDetailView actividad={this.props.actividad} />
			</div>
		);
	}
};

export class ActivityContainer extends Component {
	render() {
		return (
			<div className="container">
				<JumbotronActividad
							actividad={this.props.actividad}
						/>
				<div className="container">
					<div style={{ display: this.props.actividad.encontrado ? 'block' : 'none' }}>
						
						<ActivityDetail
							actividad={this.props.actividad}
							mibaLogueado={this.props.mibaLogueado}
							procesoInscripcion={this.props.procesoInscripcion}//izaregon599
							listInscripciones={this.props.listInscripciones}	//izaregon599
							onLogin={this.props.onLogin}
							viewFormsInscripction={this.props.viewFormsInscripction}//izaregon683	
							deleteCuposInscripction={this.props.deleteCuposInscripction}//izaregon683	
							inscritoEsMenor={this.props.inscritoEsMenor}
							adultoObligatorio={this.props.adultoObligatorio}//izaregon412
							onBirthdateChange={this.props.onBirthdateChange}
							onInputChange={this.props.onInputChange}
							enviarImagen={this.props.enviarImagen}//izaregon408
							onSubmitForm={this.props.onSubmitForm}
							/* captchaHandler={this.props.captchaHandler} */
							captchaIDHandler={this.props.captchaIDHandler}
							formularioProcesado={this.props.formularioProcesado}
							inscripcionConfirmada={this.props.inscripcionConfirmada}
							codigoInscripcion={this.props.codigoInscripcion}
							fechaNacimiento={this.props.fechaNacimiento}
							handleDownloadPdf={this.props.handleDownloadPdf} />
					</div>
					<div style={{ display: this.props.actividad.encontrado === false ? 'block' : 'none' }}>
						<h1>No se encontró la actividad solicitada</h1>
					</div>
				</div>
			</div>
		);
	}
};

class BreadcrumbActividad extends Component {
	render() {
		var actividad = this.props.actividad;
		return (
			<Breadcrumb descripcion={actividad.nombreTaller} beSmall={true}>
				<BreadcrumbLinkLanding />
				<BreadcrumbLinkMinisterio
					ministerio={{
						descripcion: actividad.nombreMinsterio,
						url: actividad.urlMinisterio}} />
				<BreadcrumbLinkReparticion
					reparticion = {{
						descripcion: actividad.descripcionReparticion,
						id: actividad.idReparticion
					}} />
			</Breadcrumb>
		)
	}
}

class JumbotronActividad extends Component {
	render() {
		var actividad = this.props.actividad;
		var oStyles = {
			backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)) , url(${ actividad.imagen })`,
			height: "100%",
			width: "100%",
			backgroundRepeat: "no-repeat",
			backgroundSize: "cov"
        };
		return (
			<div className="jumbotron jumbotron-main jumbotron-small m-jumbotron-cultura-activity mb-4" style={oStyles}>
				<nav role="navigation">
					<BreadcrumbActividad actividad={actividad} />
				</nav>
				<div className="container text-left">
					{false ? <h1 className="text-white">{actividad.nombreTaller}</h1> : null}
					<h3 className="text-white">{actividad.nombreTaller}</h3>
					<p className="lead text-white">{actividad.descripcion}</p>
				</div>
			</div>
		);
	}
};

export class Actividad extends Component {

	constructor(props) {
		super(props);
		var oInitialActivity = $.extend({}, oEmptyActivity)
		oInitialActivity.idTaller = this.props.match.params.idTaller;
		oInitialActivity.idEncuentro = this.props.match.params.idEncuentro;

		this._getActivity = this._getActivity.bind(this);
		/* this._captchaHandler = this._captchaHandler.bind(this); */
		this._captchaIDHandler = this._captchaIDHandler.bind(this);
		this._mibaLogin = this._mibaLogin.bind(this);
		this.viewFormsInscripction = this.viewFormsInscripction.bind(this);//izaregon683
		this.deleteCuposInscripction = this.deleteCuposInscripction.bind(this);//izaregon683
		this.onInputChange = this.onInputChange.bind(this);
		this.enviarImagen = this.enviarImagen.bind(this);//izaregon408		
		this.onSubmitForm = this.onSubmitForm.bind(this);
		this.handleBirthdateChange = this.handleBirthdateChange.bind(this);
		this._handleDownloadPdf = this._handleDownloadPdf.bind(this);
		
		this.state = {
			/* overlayIsActive: true, */
			idimagen:'',//izaregon 408
			listInscripciones:{
				inscripcion:[],
				formularioInsc:[]
			},
			procesoInscripcion:{
				secciones:false,
				cuposAInscribirse: 0,
				inscripcionesRealizadas:0,
				lote:-1,
				validate:-1,
				openSesion:false
			} ,
			actividad: oInitialActivity,
			mibaLogueado: false,
			inscripcion: {
				formularioProcesado: false,
				inscripcionConfirmada: false,
				codigoInscripcion: ""
			},
			formulario: {
				datosInscripto: {},
				datosAdultoResponsable: {}
			},
			captchaWidgetID: -1
		};

	}

	 getCuposInscripcion(idEncuentro,solicitudCupos,lote,cuposSolicitud,consulta){//izaregon599
		return new Promise(function (resolve,reject){
			$.ajax({
				type:"get",
				async:false,
				dataType:'json', 
				url: SERVICE_URL_DOMAIN + "/InscripcionCargaMasiva.xsjs/?idEncuentro="+idEncuentro+"&numeroCupos="+solicitudCupos+"&lote="+lote+"&cuposSolicitud="+cuposSolicitud+"&consulta="+consulta,
				success: function (data) {
					resolve(data);
				},
				error: function (xhr, textStatus, errorMessage) {
					reject(0);
				}
		
			});
		});
	 }
	 viewFormsInscripction(){
		this.setState({ 
			mibaLogueado:true
		});
	 }
	 async deleteCuposInscripction(){
		var numero=parseInt(document.getElementById("idCountInscrip").value, 10); 
		var idEncuentro=parseInt(this.state.actividad.idEncuentro, 10);
		//Valida que de exisitir una sesion con inscripciones sin completar, se elimine e inicie de nuevo
		var cuposSolicitud=(localStorage.getItem('cuposSolicitud')!==null)?parseInt(localStorage.getItem('cuposSolicitud'),10):-1;
		var lote=(localStorage.getItem('lote')!==null)?parseInt(localStorage.getItem('lote'),10):-1;
		var data=await this.getCuposInscripcion(idEncuentro,numero,lote,cuposSolicitud,0);
		localStorage.clear();
		this.setState({ 
			mibaLogueado: false,								
			procesoInscripcion:{					
				secciones:false,
				cuposAInscribirse: 0,
				inscripcionesRealizadas:0,
				lote:-1,
				validate:-1,
				openSesion:false 
			} 
		});
	 }
	 async _mibaLogin() {
		// this.setState({ mibaLogueado: true });
		if(this.state.actividad.Formulario.cargaMasiva===1){//izaregon599
			var numero=parseInt(document.getElementById("idCountInscrip").value, 10); 
			var idEncuentro=parseInt(this.state.actividad.idEncuentro, 10);
			//Valida que de exisitir una sesion con inscripciones sin completar, se elimine e inicie de nuevo
			var lote=(localStorage.getItem('lote')!==null)?parseInt(localStorage.getItem('lote'),10):-1;
			var cuposSolicitud=(localStorage.getItem('cuposSolicitud')!==null)?parseInt(localStorage.getItem('cuposSolicitud'),10):-1;
			var data=await this.getCuposInscripcion(idEncuentro,numero,lote,cuposSolicitud,1);
			// var mibaLogueado=true;
			// localStorage.setItem('Formulario', JSON.stringify(this.state.actividad.Formulario) );
			localStorage.setItem('lote', data.lote);
			localStorage.setItem('cuposSolicitud', numero);
			// var mibaLogueado=false;
			// if(data.validate===1){  mibaLogueado=true;}
			this.setState({ 
				mibaLogueado: (data.validate===1)?true:false,				
				procesoInscripcion:{					
					secciones:false,		
					cuposAInscribirse: numero,
					inscripcionesRealizadas:0,
					lote:data.lote,
					validate:data.validate,
					openSesion:false  
				} 
			});
		}else{
			this.setState({ mibaLogueado: true });
		}		
	}
	redirect() {
		window.location.replace('/');
	}
	_getActivity (oActivityKey) {
		var sSelect = [
			"imagen",
			"idTaller",
			"idEncuentro",
			"estadoEncuentro",
			"codigoMinisterio",
			"nombreTaller",
			"nombreSede",
			"direccionSede",
			"barrioSede",
			"fechaMinimaInscripcion",
			"fechaMaximaInscripcion",
			"inscripcionAbierta",
			"fechaInicio",
			"fechaFin",
			"dias",
			"horaInicio",
			"horaFin",
			"abreviacionTipoSede",
			"descripcionEncuentro",
			"nombreEncuentro",
			"descripcionDisciplina",
			"descripcionTaller",
			"descripcionReparticion",
			"nombreMinsterio",
			"urlInscripcion",
			"urlMinisterio",
			"idReparticion",
			"idFormulario",
			"precioTaller",
			"edadMin",
			"edadMax",
			"vPeriodo",
			"vDisciplina",
			"vAutorizacion",
			"vSede","urlPersonalizada",//izaregon1284
			"vBarrio",
			"vEdades",
			"vDireccion",
			"vHorario",
			"validFechaNacimiento",
			"fechaNacimientoMinima",
			"fechaNacimientoMaxima",
			"Formulario",
			"text_inscripcion",
			"tipo",
			"textoPdf","vDireccionPdf","vDuracionPdf","vDictadoPdf","vBannerPdf","vSedePdf",//izaregon1159
			"Formulario/Seccion/nombre",
			"Formulario/Seccion/seccionAdultoResponsable",
			"Formulario/Seccion/Campo/id",
			"Formulario/Seccion/Campo/tipo.id",
			"Formulario/Seccion/Campo/control",
			"Formulario/Seccion/Campo/label",
			"Formulario/Seccion/Campo/listItem",
			"Formulario/Seccion/Campo/required",
			"Formulario/Seccion/Campo/orden",
			"Formulario/Seccion/Campo/enabled",
			"Formulario/Seccion/Campo/visible",
			"Formulario/Seccion/Campo/maximaLongitud",
			"Formulario/Seccion/Campo/control",
			"Formulario/Seccion/Campo/placeHolder"
		].join(',');
		
		var sTallerUrl = SERVICE_URL_DOMAIN + "/Taller.xsodata/Oferta"
		this.props.loadingStateCounter.increase();
		$.ajax({
			url: sTallerUrl,
			data: {
				$select: sSelect,
				$filter: `idEncuentro eq ${oActivityKey.idEncuentro}`,
				$expand: [
					'Formulario',//izaregon412					
					'Formulario/Seccion',
					'Formulario/Seccion/Campo'
				].join(',') 
			},
			dataType: 'json',
			cache: false
		})
			.done(function (data, textStatus, jqXHR) {
				var oActividad = data && data.d && data.d.results ? data.d.results : null;
				var checkBirthday = false;						
				if (oActividad !== null) {
					oActividad = oActividad.length > 0 ? oActividad[0] : oActividad;
					oActividad.encontrado = true;
					oActividad.inscritoEsMenor = false;
					if (oActividad.edadMin || oActividad.edadMax) {
						checkBirthday = true;
					}
				} else {
					oActividad = {
						idEncuentro: oActivityKey.idEncuentro,
						encontrado: false
					};
				}
				// oActividad.Formulario.adultoObligatorio=1;//izaregon412
				// FormularioS:$.extend( {}, oActividad.Formulario ),
				this.setState({
					actividad: oActividad,
					FormularioS: JSON.parse(JSON.stringify(oActividad.Formulario)),//izaregon702
					checkBirthday: checkBirthday
				});
			}.bind(this))
			.fail(function (jqXHR, textStatus, errorThrown) {
				console.log("Error", jqXHR, textStatus, errorThrown);
				if (jqXHR.status === 404) {
					var oActividad = {
						idEncuentro: oActivityKey.idEncuentro,
						encontrado: false
					};

					this.setState({
						actividad: oActividad						
					});
				} 
				this.redirect(this.props);
				// else {
				// 	alert("Ocurrió un error al intentar obtener la actividad. Intente más tarde nuevamente.");
				// }
			}.bind(this))
			.always(this.props.loadingStateCounter.decrease);
	}

	_hasAgeLimit() {
		return !!(this.state.actividad.edadMin || this.state.actividad.edadMax);
	}

	_checkBirthday(age) {
		var ageIsValid = true;
		if (this.state.actividad.edadMin) {
			ageIsValid = ageIsValid && age >= this.state.actividad.edadMin;
		}
		if (this.state.actividad.edadMax) {
			ageIsValid = ageIsValid && age <= this.state.actividad.edadMax;
		}
		return ageIsValid;
	}

	_captchaIDHandler(id) {
		this.setState({captchaWidgetID: id});
	}
	_getBarrios(){//+ecarpiod
		return new Promise(function (resolve,reject){
			$.ajax({
				type:"get",
				async:false,
				dataType:'json',
				url: SERVICE_URL_DOMAIN + "/MasterService.xsodata/Barrio?$orderby=descripcion",
				success: function (data) {
					resolve(data.d.results);
				},
				error: function (xhr, textStatus, errorMessage) {
					reject(errorMessage);
				}

			});
		});
	}

	cargar(file,sInputId) {
		var that = this;
		function setImagen(imagen){			 
			var formulario = $.extend({}, that.state.formulario);
			// var sInputId = oEvent.target.dataset.fieldid;
			formulario.datosInscripto[that.state.idimagen] = imagen;
			that.setState({formulario: formulario});			
		}
		function cargarArchivo(file,sInputId) {
			return new Promise(function(resolve,reject){
				if(typeof(file) != "string"){
					var reader=new FileReader();
					reader.onloadend=function(){
						resolve(reader.result,sInputId);
					}
					reader.onerror=function(e){
						reject(e);
					}
					reader.readAsDataURL(file);						
				}else{
					resolve(file);
				}
			});
		}
		 cargarArchivo(file,sInputId).then(function(imagen,sInputId){		
			setImagen(imagen);			
			});
		
	}
	cargarSinPromesa(fileToLoad,oEvent){  //En caso sin promesa, carga de imagen
		var fileReader = new FileReader();
		var base64;	
		fileReader.onload = function(fileLoadedEvent) {
			base64 = fileLoadedEvent.target.result;				
			console.log(base64);
		};	
		fileReader.readAsDataURL(fileToLoad);
		return fileToLoad;
	}
	async enviarImagen(oEvent,file){
		var file = file || oEvent.target.files[0];
		var sInputId = oEvent.target.dataset.fieldid;
		//izaregon476
		if(file===undefined){	document.getElementById("field-"+sInputId).value = '';	}
		this.setState({idimagen: sInputId});
	
		var arrayTipo=['pdf','jpg','png','PDF','JPG','PNG'];
			            if (file.size <= 5000000 ){
							var tam=file.name.length;
							var tipo=file.name.substring(tam-3,tam);
							if(arrayTipo.indexOf(tipo)===-1){
								alert('Solo se permiten archivos del tipo pdf, png y jpg, este archivo no será considerado en la inscripción');
								//izaregon476
								document.getElementById("field-"+sInputId).value = '';	
							}else{
								this.cargar(file,sInputId);
							}
							
						}else{
							alert('El archivo pesa mas que 5Mb, por lo tanto no será considerado en la inscripción');
							//izaregon476
							document.getElementById("field-"+sInputId).value = '';	
						}
	}
	async  onInputChange(oEvent) {
		
		var formulario = $.extend({}, this.state.formulario);
		var sInputId = oEvent.target.dataset.fieldid;
		var sTipoinput = oEvent.target.dataset.tipoinput;
		var value;
		switch (oEvent.target.type) {
			case "checkbox":
				value = oEvent.target.checked;
				break;				
			default:
				value = oEvent.target.value;
				break;
		}
		if(sTipoinput==="3"){
			value=value.toUpperCase();
			formulario.datosInscripto[sInputId] = value;
			formulario[sInputId] = value;
			document.getElementById('field-'+sInputId).value=value;
		}
		if (oEvent.target.dataset.adultomayor != 0) {//*ecarpiod
			formulario.datosAdultoResponsable[sInputId] = value
		} else {
			formulario.datosInscripto[sInputId] = value;
		}
		formulario[oEvent.target.dataset.fieldid] = oEvent.target.value;
		
		this.setState({formulario: formulario});
		//+ecarpiod{
		//tipoinput=11 (PROVINCIA), tabla: Formulario.TipoInput ,izaregon588
		if((oEvent.target.dataset.tipoinput?oEvent.target.dataset.tipoinput:null)==="11"){
			

			var seccion=this.state.actividad.Formulario.Seccion;
			var inputsArray=[];
			if(this.state.FormularioS.cargaMasiva===1){
				 if(this.state.procesoInscripcion.inscripcionesRealizadas>0){
					inputsArray=seccion.results[0].Campo.results;
				}else{inputsArray=seccion.results[1].Campo.results;}
			}else{inputsArray=seccion.results[0].Campo.results;}
			var barrio;//,izaregon588
			for(var i=0;i<inputsArray.length;i++){
				if( inputsArray[i].label==="Localidad/Barrio" && inputsArray[i]["tipo.id"]==="12" ){
					barrio=inputsArray[i];
				}
			}
			// var barrio=seccion.results[0].Campo.results[9];
			if(document.getElementById('field-'+barrio.id)!=null){//izaregon588
				var oldDiv=document.getElementById('field-'+barrio.id).parentElement.parentElement;
				var parentDiv=oldDiv.parentElement;
			}

			try{
				if( (oEvent.target.value?oEvent.target.value:null)=="Ciudad de Buenos Aires"){
						barrio.control="combobox";
						var barrios=await this._getBarrios();
						barrios=barrios.map(function(barrio){
							return barrio.descripcion;
						});
						barrio.listItem=barrios.toString();
						var newDiv=<ActivityDetailFormSectionFields
							campo={barrio}
							key={barrio.id}
							seccionAdulto={seccion.results[0].seccionAdultoResponsable}
							onBirthdateChange={this.handleBirthdateChange}
							onInputChange={this.onInputChange}
							inscritoEsMenor={this.state.actividad.inscritoEsMenor}
						/>;
				}else{
					barrio.control="input"
					var newDiv=<ActivityDetailFormSectionFields
						campo={barrio}
						key={barrio.id}
						seccionAdulto={seccion.results[0].seccionAdultoResponsable}
						onBirthdateChange={this.handleBirthdateChange}
						onInputChange={this.onInputChange}
						inscritoEsMenor={this.state.actividad.inscritoEsMenor}				
					/>;
				}
				ReactDOM.render(
					newDiv,
					document.getElementsByClassName('div-field-'+barrio.id)[0]//izaregon588
				)
			}catch(e){
				alert('Error al obtener barrios');
			}


		}
		//}+ecarpiod
	
	}

	_validateInputs() {
		var bValidInputs = true;
		var oMails = {
			original: {
				value: "",
				container: null
			},
			confirm: {
				value: "",
				container: null
			}
		};
		var aSections = this.state.actividad.Formulario.Seccion.results;

		var dni; 
		for (var i = 0; i < aSections.length; i++) {
			
			if (aSections[i].seccionAdultoResponsable === 1 && !this.state.actividad.inscritoEsMenor){
				if(this.state.actividad.Formulario.adultoObligatorio!=1){//izaregon484
					continue;
				}
			}
			var aFields = aSections[i].Campo.results;
			
			
			
			for (var j = 0; j < aFields.length; j++) {
				var regex4 = /^[A-Z0-9]*$/; var regex5 = /^[0-9]*$/;
				var oField = document.getElementById("field-" + aFields[j].id);
				var oContainer = $(oField).closest(".form-group");
				var tipoInputVal= parseInt(aFields[j]["tipo.id"], 10);
				// if (oField.dataset.required == 1 || (tipoInputVal>16 && tipoInputVal <22)) {//*validacion de adicionales,1266
				if (oField.dataset.required == 1 ) {//*validacion de adicionales,1266
					var bValidInput = -1;
					var sError = "";
					switch (oField.type) {
						case "checkbox":
							bValidInputs = bValidInputs && true;
							break;							
						case "email":
							if (aSections[i].seccionAdultoResponsable === 0 && this.state.actividad.inscritoEsMenor) {
								oField.value = "";
								bValidInput = true
							} else {
								var regex = /(?:[a-zA-Z0-9!#$%&'*+/=?^_'{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_'{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
								bValidInput = regex.test(oField.value.trim());

								if (!bValidInput) {
									if (oField.value.trim() === "")
										sError = "Este campo es obligatorio";
									else
										sError = "La dirección de email que pusiste es inválida";
								}
								if (oField.dataset.inputTypeId == 5) //*ecarpiod
									oMails.original.value = oField.value;
								else
									oMails.confirm.value = oField.value;

								if (oField.dataset.inputTypeId == 5) //*ecarpiod
									oMails.original.container = oContainer;
								else {
									oMails.confirm.container = oContainer;
									if (oMails.original.value !== oMails.confirm.value) {
										bValidInput = false;
										sError = "Las direcciones de email deben coincidir";
									}
								}
							}
							break;
						case "date":
							if(moment(oField.value).isValid()) { //Es fecha válida
								var oDate = new Date(oField.value);
								var age = moment().diff(moment(oDate, 'yyyyMMdd'), 'years');
								var days = moment().diff(moment(oDate, 'yyyyMMdd'), 'days');
								if (days <= 0 || oDate.getFullYear() < 1900) {
										bValidInput = false;
										sError = "Debe ingresar una fecha válida.";
								}
								//izaregon592
								if(this.state.actividad.validFechaNacimiento==0){	//la validacion sera por edad
									if (this._hasAgeLimit() && (!this._checkBirthday(age))) {
											bValidInput = false;
											sError = "No cumple el requisito de edad";
									}
								}else{
									var fechaArray = oField.value.split('-'); // dividir la cadena en un array
									var fechaFormateada = fechaArray[2] + '/' + fechaArray[1] + '/' + fechaArray[0]; // formatear la cadena de fecha

									const fechaNacimientoMinima = new Date(this.state.actividad.fechaNacimientoMinima.split("/").reverse().join("-"));
									const fechaNacimientoMaxima = new Date(this.state.actividad.fechaNacimientoMaxima.split("/").reverse().join("-"));
									 fechaFormateada = new Date(fechaFormateada.split("/").reverse().join("-"));

									 const tiempo_fechaNacimientoMinima = fechaNacimientoMinima.getTime();
									const tiempo_fechaNacimientoMaxima = fechaNacimientoMaxima.getTime();
									const tiempo_fechaFormateada = fechaFormateada.getTime();
									// var componentesFecha = fechaFormateada.split('/');
									// var fechaFormateada = new Date(componentesFecha[2], componentesFecha[1] - 1, componentesFecha[0]);

									if(tiempo_fechaFormateada >=tiempo_fechaNacimientoMinima && 
										tiempo_fechaFormateada <=tiempo_fechaNacimientoMaxima){

										
												bValidInput=-1;
														sError = "";
										
									}else{
										bValidInput=false;
										sError = "La fecha de nacimiento no esta en el rango solicitado para este formulario.";
									}
									// new Date(this.state.actividad.fechaNacimientoMaxima).getTime();
								}
							}else{
								bValidInput=false;
								sError = "Debe ingresar una fecha válida.";
							}
							break;
						case "select-one":
							bValidInput = oField.value.trim() !== "";
							if (!bValidInput)
								sError = "Tenés que elegir una opción";
							break;
						//izaregon483{
						case "file":
							bValidInput = oField.value.trim() !== "";
							if (!bValidInput){
									sError = "Tenés que adjuntar el archivo indicado";
							}
							else {						
							var arrayTipo=['pdf','jpg','png','PDF','JPG','PNG'];
						
								var tam=oField.value.length;
								var tipo=oField.value.substring(tam-3,tam);
								if(arrayTipo.indexOf(tipo)===-1){
									sError = "El archivo seleccionado no esta permitido";								
									
								}else{
									bValidInput = true;
								}
								
							}	
							break;
							//}izaregon483
						default:
							
							
							var regex2 = /^[1-9]\d*$/;
							if (aFields[j].control === "number") {
								
								
									if ( oField.dataset.tipoinput === "3"){										
										var idTipoDocPrincipal=0; var idTipoDocTutor=0;//izaregon712
										for (var z = 0; z < aFields.length; z++) {
											if( aFields[z].label==="Tipo de documento" && aFields[z]["tipo.id"]==="13" ){//izaregon712
												if(aFields[z].datoDeAdultoResponsable=== 1){
													idTipoDocTutor=aFields[z].id;
												}else{ idTipoDocPrincipal=aFields[z].id;}
												
											}
										}
										var tipoDocPrincipal=(idTipoDocPrincipal!==0)? document.getElementById('field-'+idTipoDocPrincipal).value : 0;
										var tipoDocTutor=(idTipoDocTutor!==0)? document.getElementById('field-'+idTipoDocTutor).value : 0;
										// Checkear que 'parece' un dni...
										var value = oField.value.replace(/^0+/, ''); // saca los ceros a la izquierda
										if(tipoDocPrincipal!==0){
											if (tipoDocPrincipal==='PASAPORTE' && (value.length < 9 || value.length >9) ) {
												bValidInput = false;
											}else{
												if (tipoDocPrincipal!=='PASAPORTE'){
													if (tipoDocPrincipal==='DNI'){
														if(value.length > 6 && value.length	< 9) {
															bValidInput = value.trim() !== "" && regex2.test(value.trim());
														}else{
															bValidInput = (tipoDocPrincipal !== "")? false:true; 
														}
													}else{
														if(value.length=== 8) {
															bValidInput = value.trim() !== "" && regex2.test(value.trim());
														}else{
															bValidInput = (tipoDocPrincipal !== "")? false:true; 
														}
													}
												}else{													
													bValidInput = (tipoDocPrincipal !== "")? regex4.test(value.trim()):true;
												}
											} 
											tipoDocPrincipal=0;
										}else{
											if(tipoDocTutor!==0){
												if (tipoDocTutor==='PASAPORTE' && (value.length < 9 || value.length >9) ) {
													bValidInput = false;
												}else{
													if (tipoDocTutor!=='PASAPORTE'){
														if (tipoDocTutor==='DNI'){
															if(value.length > 6 && value.length	< 9) {
																bValidInput = value.trim() !== "" && regex2.test(value.trim());
															}else{															
																bValidInput = (tipoDocTutor !== "")? false:true;
															}
														}else{	
															if(value.length=== 8) {
																bValidInput = value.trim() !== "" && regex2.test(value.trim());
															}else{															
																bValidInput = (tipoDocTutor !== "")? false:true;
															}
														}
													}else{
														bValidInput = value.trim() !== "" && regex4.test(value.trim());														
													}
												} 
												tipoDocTutor=0;
											}
										}

										if (!bValidInput) {											
											sError = "Tenés que ingresar un documento válido";
										} else {
											if(this.state.actividad.Formulario.cargaMasiva===0){//izaregon599
												if ( aSections[i].seccionAdultoResponsable === 0 ){
													dni = oField.value;
												}else{
													if ( oField.value === dni ){
														bValidInput = false;
														sError = "El documento del menor no puede ser igual al del Adulto Responsable";
													}
												}
											}else{//izaregon599		
												if(this.state.procesoInscripcion.inscripcionesRealizadas===0 && aSections[i].seccionAdultoResponsable === 1){
													localStorage.setItem('dniAdulto', oField.value);
												}		
												else{
													var dniAdulto=(localStorage.getItem('dniAdulto')!==null)?(localStorage.getItem('dniAdulto')):'-1';
													if ( oField.value === dniAdulto ){
														bValidInput = false;
														sError = "El documento del menor no puede ser igual al del Adulto Responsable";
													}
												}
											}
										}
									}else{
										bValidInput = oField.value.trim() !== "" && regex2.test(oField.value.trim());
										if (!bValidInput) {
											if (oField.value.trim() === "")
												sError = "Este campo es obligatorio"; // FIXME: WTF?
											else
												sError = "Tenés que ingresar solo números";
										} else{
											}
									}
							} else {
								var regex3 = /^[A-Za-zÀ-ÿ0-9 ]*$/;//izaregon1266
								sError = "Solo se permiten letras, números y espacios";
								
								if ( tipoInputVal>16 && tipoInputVal <22) {//valor de inputs adicionales
									 regex3 = /^[A-Za-zÀ-ÿ0-9,. ]*$/;//izaregon1266
									 sError = "Solo se permiten letras, números, espacios, puntos (.) y comas (,)";
								}								
								
								bValidInput = oField.value.trim() !== "" && regex3.test(oField.value.trim());
								if (!bValidInput) {
									if (oField.value.trim() === "")
										sError = "Este campo es obligatorio";
									// else
									// 	sError = "Solo se permiten letras, números, espacios, puntos (.) y comas (,)";
								}
							}
							break;
					}
				} else {
					switch (aFields[j].label) {
						case "Teléfono":
						case "Celular":

							if (aFields[j].control === "number") {
								if(oField.value!==undefined && oField.value!=="") {
									if(oField.value.length<=20) {
										bValidInput = (oField.value.trim() !== "")? regex5.test(oField.value.trim()):true;
										sError=(bValidInput)?"":"Tenés que ingresar solo números";
									}else{
										sError = "Tenés que ingresar un numero de máximo 20 cifras";
										bValidInput=false;
									}
								}else{bValidInput=true;}
							}
							break;
						default:
							bValidInput = true;
							break;
					}
					
				}

				if (bValidInput !== -1) {
					if (bValidInput) {
						oContainer.removeClass("has-error");
						oContainer.addClass("has-success");
						$('#' + oField.id + "-error").css("visibility", "hidden");
					} else {
						oContainer.addClass("has-error");
						oContainer.removeClass("has-success");
						$('#' + oField.id + "-error").css("visibility", "visible");
						$([document.documentElement, document.body]).animate(
							{scrollTop: oContainer.offset().top
						}, 100);
					}

					bValidInputs = bValidInputs && bValidInput;
				}
				$('#' + oField.id + "-error").text(sError);
			}
		}
		// izaregon-captcha{
		// if (window.grecaptcha.getResponse(this.state.captchaWidgetID) === "") {
		// 	bValidInputs = false;
		// 	$('#form-recaptcha-alert').css('display', 'block');
		// } else {
		// 	$('#form-recaptcha-alert').css('display', 'none');			
		// }
		// }izaregon-captcha

		return bValidInputs;
	}

	onSubmitForm(oEvent) {
		
		if(this.state.actividad.Formulario.cargaMasiva===0){
			this.props.loadingStateCounter.increase();
		}
		var valid=this._validateInputs();
		if(valid){//izaregon1061
			
			if(this.state.actividad.Formulario.urlTerminos!=null && this.state.actividad.Formulario.urlTerminos!=0){
				valid=document.getElementById("ChTerminos").checked; 
				if(!valid){
					document.getElementById("ChTerminos").focus();
					alert('Por favor acepte los terminos y condiciones');
				}
				
			}
		}
		// if (!this._validateInputs()) {			
		// 		this.props.loadingStateCounter.decrease();
		// 		return;			
		// }
		if (!valid && this.state.actividad.Formulario.cargaMasiva===0) {			
				this.props.loadingStateCounter.decrease();
				return;			
		}
		// if (!valid && this.state.actividad.Formulario.cargaMasiva===1) {			
		// 	window.grecaptcha.reset();		
		// }
		if (valid ) {	
			if(this.state.actividad.Formulario.cargaMasiva===1){
				this.props.loadingStateCounter.increase();
			}
		// $.ajax({
		// 	// url: "/destinations/GoogleRecaptcha/siteverify?secret=6LfoVJMUAAAAAKqbc5KjL1XksrWLu2xwBafjFHuV&response=" + this.state.captchaRespose,
		// 	url: process.env.REACT_APP_GOOGLE_CAPTCHA_URL,
		// 	data: {
		// 		secret: process.env.REACT_APP_GOOGLE_CAPTCHA_SECRET,
		// 		response: window.grecaptcha.getResponse(this.state.captchaWidgetID)
		// 	},
		// 	method: "POST",
		// 	dataType: 'json',
		// 	cache: false,
		// 	// data: JSON.stringify({})
		// })
		// .done(function(data, textStatus, jqXHR) {
		// 	if (data.success !== true) {
		// 		window.scrollTo(0,0);
		// 		$('#form-recaptcha-alert').css('display', 'block');;
		// 		// this.props.loadingStateCounter.decrease();
		// 		return;	
		// 	}
		// 	if(this.state.actividad.Formulario.cargaMasiva===1){			
		// 		this.setState({							
		// 			procesoInscripcion:{ 
		// 				...this.state.procesoInscripcion,
		// 				// secciones:true,
		// 				inscripcionesRealizadas:this.state.procesoInscripcion.inscripcionesRealizadas+1
		// 			}
		// 		});				
		// }
this.props.loadingStateCounter.decrease();
			var datosForm = $.extend({}, this.state.formulario.datosInscripto);
			 
			if (this.state.actividad.inscritoEsMenor) {
				if((this.state.procesoInscripcion.inscripcionesRealizadas+1)>1 && this.state.actividad.Formulario.cargaMasiva===1){//se le resta uno por que en always ya se habia incrementado
					datosForm=$.extend(datosForm, this.state.listInscripciones.formularioInsc[0].datosAdultoResponsable);
				}else{
					datosForm = $.extend(datosForm, this.state.formulario.datosAdultoResponsable);
				}
				// datosForm = $.extend(datosForm, this.state.formulario.datosAdultoResponsable);
			}else{//izaregon485
				if(this.state.actividad.Formulario.adultoObligatorio===1){
					if((this.state.procesoInscripcion.inscripcionesRealizadas+1)>1 && this.state.actividad.Formulario.cargaMasiva===1){//se le resta uno por que en always ya se habia incrementado
						datosForm=$.extend(datosForm, this.state.listInscripciones.formularioInsc[0].datosAdultoResponsable);
					}else{
						datosForm = $.extend(datosForm, this.state.formulario.datosAdultoResponsable);
					}
				}
			}
			console.log(this.datosForm);
			var inscripcionesRealizadas=(this.state.procesoInscripcion.inscripcionesRealizadas===0)?1:this.state.procesoInscripcion.inscripcionesRealizadas+1;
			var oData = {
				usuario:'',
				enEspera:this.state.procesoInscripcion.validate,
				cuposAInscribirse:this.state.procesoInscripcion.cuposAInscribirse,
				inscripcionesRealizadas:inscripcionesRealizadas,
				formularioProcesado:this.state.inscripcion.formularioProcesado,
				lote:this.state.procesoInscripcion.lote,
				cargaMasiva: this.state.actividad.Formulario.cargaMasiva,
				idTaller: this.state.actividad.idTaller,
				idEncuentro: this.state.actividad.idEncuentro,
				codigoMinisterio: this.state.actividad.codigoMinisterio,
				formulario: datosForm,
				dni: $('.form-control[data-tipoinput="3"][data-adultomayor="0"]').val(),
				tipoDni: $('.form-control[data-tipoinput="13"][data-adultomayor="0"]').val()
			};
			this.props.loadingStateCounter.increase();
			
			$.ajax({
				url: SERVICE_URL_DOMAIN + "/Inscripcion.xsjs",
				method: "POST",
				dataType: 'json',
				cache: false,
				data: JSON.stringify(oData)
			})
			.done(function (data, textStatus, jqXHR) {
				var oState = this.state; 
				
				// if(document.getElementById("cuerpoFormulario")!==null){document.getElementById("cuerpoFormulario").innerHTML="";}
				if(data.codigoInscripcion===-2){//El valor -2  indica que se eliminarion los datos que se ingresaron anteriormente
					if(this.state.actividad.Formulario.cargaMasiva===1){//izaregon599
						alert('No se puede continuar con las inscripciones dado que se expiro el tiempo de registro, por favor intente nuevamente');
					}else{
						alert(data.Result);
					}
					// setTimeout(function(){
						window.location.reload(true);
					// },2000);		

				}
				if(this.state.actividad.Formulario.cargaMasiva===1){//izaregon599
					

					oState.procesoInscripcion.inscripcionesRealizadas = this.state.procesoInscripcion.inscripcionesRealizadas+1;
					this.props.loadingStateCounter.decrease();
					if(this.state.procesoInscripcion.cuposAInscribirse===oState.procesoInscripcion.inscripcionesRealizadas){
						// oState.actividad.Formulario= JSON.parse(localStorage.getItem('Formulario'));//izaregon702}
						oState.actividad.Formulario=oState.FormularioS;
						oState.inscripcion.formularioProcesado = true;
						
						localStorage.clear();
						// this.props.loadingStateCounter.increase();
					}else{
						
						oState.procesoInscripcion.secciones = !this.state.procesoInscripcion.secciones;	
					}
				}else{ 
					oState.inscripcion.formularioProcesado = true;
				}
				oState.inscripcion.inscripcionConfirmada = data.cupoDisponible === 1;
				oState.inscripcion.fechaNacimiento = data.fechaNacimiento;
				oState.inscripcion.codigoInscripcion = data.codigoInscripcion;

				if(this.state.actividad.Formulario.cargaMasiva===1){
					// var inscripcion = this.state.listInscripciones.inscripcion ?? [];
					// var formularioInsc = this.state.listInscripciones.formularioInsc ?? [];
					var inscripcion=(this.state.listInscripciones.inscripcion===[])?[]:this.state.listInscripciones.inscripcion;//izaregon599
					var formularioInsc=(this.state.listInscripciones.formularioInsc===[])?[]:this.state.listInscripciones.formularioInsc;//izaregon599 
					var inscrito={};
					inscrito.codigoInscripcion=data.codigoInscripcion;
					inscrito.fechaNacimiento=data.fechaNacimiento;

					inscripcion.push(inscrito); 
					formularioInsc.push(this.state.formulario);
					oState.listInscripciones.inscripcion = inscripcion;
					oState.listInscripciones.formularioInsc = formularioInsc;
										
				}
				this.setState(oState);
				var oDniInput = $('input[data-tipoinput="3"][data-adultomayor="0"]')[0];
				if (oDniInput) {
					var oContainer = $(oDniInput).closest(".form-group");
					var $errorInput = $('#' + oDniInput.id + "-error");
					$errorInput.text("");
					$errorInput.css("visibility", "hidden");
					oContainer.removeClass("has-error");
					oContainer.addClass("has-success");
				}

				$('#error-mismo-horario').remove();	
			}.bind(this))
			.fail(function (jqXHR, textStatus, errorThrown) {
				var oContainer="";
				// if(this.state.actividad.Formulario.cargaMasiva===1){
				// 	this.setState({					
				// 		procesoInscripcion:{ 
				// 			...this.state.procesoInscripcion,
				// 			inscripcionesRealizadas:this.state.procesoInscripcion.inscripcionesRealizadas-1
				// 			// ,secciones:!this.state.procesoInscripcion.secciones
							
				// 		}
				// 	});
				// }
				if (jqXHR.status === 500) {
					var oDniInput = $('input[data-tipoinput="3"][data-adultomayor="0"]')[0];
					if (oDniInput) {
						oContainer = $(oDniInput).closest(".form-group");
						var $errorInput = $('#' + oDniInput.id + "-error");
						$errorInput.text("Ya estás registrado/a en esta actividad");
						$errorInput.css("visibility", "visible");
						oContainer.addClass("has-error");
						oContainer.removeClass("has-success");
					}
				} else if (jqXHR.status === 302) {
					if($("#error-mismo-horario")[0] === undefined){
						oContainer = '<div id="error-mismo-horario" class="alert alert-danger">Ya estás registrado/a en una actividad con el mismo horario.</div>';
						// $(oContainer).insertAfter( "#form-recaptcha-alert" );
					}			
				} else {
					alert(jqXHR.responseJSON.Result);//izaregon960
					window.location.reload(true);
					// if(jqXHR.responseJSON.estadoEncuentro===1){
					// 	var url=window.location.origin;
					// 	window.location.href=url;
					// }else{
					// 	// alert("Ocurrió un error al procesar tu inscripción. Intentá de nuevo por favor.");
					// 	if(this.state.actividad.Formulario.cargaMasiva===1){//izaregon599					
					// 		window.location.reload(true);
					// 	}
					// }
				}
			}.bind(this))
			.always( this.props.loadingStateCounter.decrease);

		// }.bind(this))
		// .fail(function(jqXHR, textStatus, errorThrown) {
		// 	console.log("Error", jqXHR, textStatus, errorThrown );
		// 	alert("El token de reCaptcha es inválido. Volvé a chequear que no sos un robot");
		// }.bind(this))
		// .always(this.props.loadingStateCounter.decrease);
		}
	}

	handleBirthdateChange(oEvent) {
		var sParsedBirthdate = oEvent.target.value.replace(/-/g, "");
		var iAge = moment().diff(moment(sParsedBirthdate, 'YYYYMMDD'), 'years');
		var state = $.extend({}, this.state);
		state.actividad.inscritoEsMenor = iAge < 18;
		state.formulario[oEvent.target.dataset.fieldid] = oEvent.target.value;
		state.formulario.datosInscripto[oEvent.target.dataset.fieldid] = oEvent.target.value;
		this.setState(state);
	}

	componentDidMount() {
		window.scrollTo(0,0)
		var oActivityKey = {
			idTaller: this.props.match.params.idTaller,
			idEncuentro: this.props.match.params.idEncuentro
		};
		this._getActivity(oActivityKey);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.state.actividad.idEncuentro !== this.props.match.params.idEncuentro) {
			var oActivityKey = {
				idTaller: this.props.match.params.idTaller,
				idEncuentro: this.props.match.params.idEncuentro
			};
			this._getActivity(oActivityKey);
		}
	}

	_getPdfWorkshopInfo() {		
		var codigoInscripcionPDF=[];//izaregon599
		
		if(this.state.procesoInscripcion.inscripcionesRealizadas>1){
			// this.state.listInscripciones[0].inscripcion.codigoInscripcion
			for (var i =0; i< this.state.listInscripciones.inscripcion.length;i++) {				 
				codigoInscripcionPDF.push({
					width: "50%",
					text: this.state.listInscripciones.inscripcion[i].codigoInscripcion
				}
				);
			  }
		}else{
			codigoInscripcionPDF={
				width: "50%",
				text: this.state.inscripcion.codigoInscripcion
			};
		}
		return [
			{
				text: "Información de la Actividad", //izaregon 460
				style: "sectionTitle"
			},
			{
				columns: [
					{
						width: "50%",
						text:[(this.state.procesoInscripcion.inscripcionesRealizadas>1)?('Inscripciones'):('Inscripción')]
						// text: "Inscripción:"
					},
					codigoInscripcionPDF
				]
			},
			{
				columns: [
					{
						width: "50%",
						text: "Actividad:"//izaregon 460
					},
					{
						width: "50%",
						text: this.state.actividad.nombreEncuentro
					}
				]
			},
			(this.state.actividad.vSedePdf===1)?//izaregon1159
			{
				columns: [
					{
						width: "50%",
						text: "Sede:"
					},
					{
						width: "50%",
						text: this.state.actividad.nombreSede
					}
				]
			}:{},		
			(this.state.actividad.vDireccionPdf===1)?//izaregon962
			{
				columns: [
					{
						width: "50%",
						text: "Dirección:"
					},
					{
						width: "50%",
						text: [(this.state.actividad.direccionSede===null)?(''):(this.state.actividad.direccionSede)]+ [(this.state.actividad.barrioSede===null||this.state.actividad.barrioSede.length<2)? (''):( ', '+this.state.actividad.barrioSede[0] + this.state.actividad.barrioSede.substr(1).toLowerCase())]
					}
				]
			}:{},
			(this.state.actividad.vDuracionPdf===1)?//izaregon962
			{
				columns: [
					{
						width: "50%",
						text: "Duración:"
					},
					{
						width: "50%",
						text: this.state.actividad.fechaInicio + " - " + this.state.actividad.fechaFin
					}
				]
			}:{},
			(this.state.actividad.vDictadoPdf===1)?{//izaregon962
				columns: [
					{
						width: "50%",
						text: "Dictado:"
					},
					{
						width: "50%",
						text: this.state.actividad.dias + " de " + this.state.actividad.horaInicio + " a " + this.state.actividad.horaFin + "hs"
					}
				]
			}:{}
		];
	}

	_getSectionInfo(oSection) {
		var aSectionContent = [{ text: oSection.nombre, style: "sectionTitle" }];
		if(this.state.actividad.Formulario.cargaMasiva===1 && oSection.seccionAdultoResponsable === 0){//izaregon599
			for(var x=0; x<this.state.listInscripciones.formularioInsc.length;x++){
				aSectionContent.push({
					text: 'Inscripcion N° '+(x+1),
					bold: true,
					fontSize: 16				
				});
				var formulario=this.state.listInscripciones.formularioInsc[x];
			// this.state.listInscripciones.formularioInsc = formularioInsc;
					oSection.Campo.results.map(function (aSectionContent, oField) {
						if (oField["tipo.id"] != 7) { //*ecarpiod
							var oResponse = formulario[oField.id];
							if (typeof oResponse !== "undefined") {
								if (oField.control !== "checkbox") {
									aSectionContent.push({
										columns: [
											{
												width: "50%",
												text: oField.label + ":"
											},
											{
												width: "50%",
												text: oResponse
											}
										]
									});
								} else {
									if (oResponse === true)
										aSectionContent.push({
											text: oField.label
										});
								}
							}
						}
					}.bind(this, aSectionContent));
			}
		}else{
		oSection.Campo.results.map(function (aSectionContent, oField) {
			if (oField["tipo.id"] != 7) { //*ecarpiod
				var oResponse = this.state.formulario[oField.id];
				if (typeof oResponse !== "undefined") {
					if (oField.control !== "checkbox") {
						aSectionContent.push({
							columns: [
								{
									width: "50%",
									text: oField.label + ":"
								},
								{
									width: "50%",
									text: oResponse
								}
							]
						});
					} else {
						if (oResponse === true)
							aSectionContent.push({
								text: oField.label
							});
					}
				}
			}
		}.bind(this, aSectionContent));
	}
		return aSectionContent;
	}

	_getPdfHeader() {
		var oCurrentDate = new Date();
		var sDay = ("00" + oCurrentDate.getDate().toString()).slice(-2);
		var sMonth = ("00" + (oCurrentDate.getMonth() + 1).toString()).slice(-2);
		return [
			{
				text: sDay + "/" + sMonth + "/" + oCurrentDate.getFullYear(),
				alignment: "right",
				margin: [0, 0, 0, 20]
			},
			{
				image: "header",
				fit: [500, 250],
				align: "center",
				margin: [0, 0, 0, 30]
			}
		];
	}

	_getPdfHeaderSinImagen(){//+ecarpiod
		var oCurrentDate = new Date();
		var sDay = ("00" + oCurrentDate.getDate().toString()).slice(-2);
		var sMonth = ("00" + (oCurrentDate.getMonth() + 1).toString()).slice(-2);
		return [
			{
				text: sDay + "/" + sMonth + "/" + oCurrentDate.getFullYear(),
				alignment: "right",
				margin: [0, 0, 0, 20]
			}
		];
	}

	_getInscriptionStatus() {
		// var sEstado = this.state.inscripcion.inscripcionConfirmada === true ? "Felicidades! Tu inscripción fue confirmada." : "Gracias por anotarte! Tu inscripción aún no fue confirmada.";
		// var sMensaje = this.state.inscripcion.inscripcionConfirmada === true ?
		// 	"Tu código de inscripción es: " + this.state.inscripcion.codigoInscripcion + ", pronto vas a recibir más información en tu mail!" :
		// 	"Lamentablemente no quedan cupos en la actividad, pero quedaste en lista de espera! Tu código de inscripción es: " + this.state.inscripcion.codigoInscripcion + ". Vas a recibir novedades sobre la inscripción en tu mail.";
		
			var sEstado ='';
			var sMensaje ='';
			if( this.state.procesoInscripcion.inscripcionesRealizadas<=1 ){
				// codigoInscripcion=this.state.inscripcion.codigoInscripcion;
				 sEstado = this.state.inscripcion.inscripcionConfirmada === true ? "Felicidades! Tu inscripción fue confirmada." : "Gracias por anotarte! Tu inscripción aún no fue confirmada.";
				 sMensaje = this.state.inscripcion.inscripcionConfirmada === true ?
				"Tu código de inscripción es: " + this.state.inscripcion.codigoInscripcion + ", pronto vas a recibir más información en tu email!" :
				"Lamentablemente no quedan cupos en la actividad, pero quedaste en lista de espera! Tu código de inscripción es: " + this.state.inscripcion.codigoInscripcion + ". Vas a recibir novedades sobre la inscripción en tu email.";
			}else{
				var codigoInscripcion='';
				for (var i =0; i< this.state.listInscripciones.inscripcion.length;i++) {			 
					if(i ===0){
						codigoInscripcion=this.state.listInscripciones.inscripcion[i].codigoInscripcion;
					}else{
						codigoInscripcion=codigoInscripcion+', '+this.state.listInscripciones.inscripcion[i].codigoInscripcion;
					}
				}
				 sEstado = this.state.inscripcion.inscripcionConfirmada === true ? "Felicidades! las inscripciones fueron confirmadas." : "Gracias por anotarte! Las inscripciones aún no han sido confirmadas.";
				 sMensaje = this.state.inscripcion.inscripcionConfirmada === true ?
				"Los códigos de las iscripciones son: " + codigoInscripcion + ", pronto vas a recibir más información en tu email!" :
				"Lamentablemente no quedan cupos en la actividad, pero las inscripciones quedaron en lista de espera! los códigos de inscripción son: " + codigoInscripcion + ". Vas a recibir novedades sobre las inscripciones en tu email.";
			}

		return [
			{
				text: sEstado,
				bold: true,
				fontSize: 16,
				margin: [0, 0, 0, 5]
			},
			{
				text: sMensaje,
				margin: [0, 0, 0, 25]
			},
			(this.state.actividad.textoPdf!==null)?//izaregon962+
			{
				text: this.state.actividad.textoPdf,
				margin: [0, 0, 0, 25]
			}:{}
		];
	}

	_getAnexoAutorizacion() {
		var sTitulo = "AUTORIZACIÓN MADRE/PADRE /TUTOR";
		var lugarFecha = "Buenos Aires,…………………  de ………………….  de 20……."
		var sMensaje = "	Por la presente autorizo a (Nombre y apellido del niño, niña o adolescente)…………………………………………………………………………, DNI …………………………………, a asistir al encuentro de ………………………………………el cual tiene lugar los días…………………………. a las ………hs. El contenido de la presente autorización, que reviste el carácter de declaración jurada, no generará ningún tipo de obligación para el Gobierno de la Ciudad Autónoma de Buenos Aires.";
		return [
			{
				text: sTitulo,
				bold: true,
				fontSize: 16,
				margin: [0, 25, 0, 0]
			},
			{
				text: lugarFecha,
				alignment: "right",
				fontSize: 12,
				margin: [0, 25, 0, 0]
			},
			{
				text: sMensaje,
				bold: false,
				justify: true,
				fontSize: 12,
				margin: [0, 25, 0, 0]
			},
			{
				margin: [0, 25, 0, 0],
				width: "40%",
				text: "Firma del Padre/Madre/Tutor debidamente acreditado:"
			},
			{
				margin: [0, 25, 0, 0],
				width: "40%",
				text: "………………………………………………………………"
			},
			{
				margin: [0, 25, 0, 0],
				width: "35%",
				text: "Aclaración:"
			},
			{
				margin: [0, 25, 0, 0],
				width: "40%",
				text: "………………………………………………………………"
			},
			{
				margin: [0, 25, 0, 0],
				width: "25%",
				text: "DNI/CI/LE:"
			},
			{
				margin: [0, 25, 0, 0],
				width: "40%",
				text: "………………………………………………………………"
			}
		];
	}
	_getBannerTaller(idTaller){//+ecarpiod
		return new Promise(function(resolve,reject){
			$.ajax({
				type: "get",
				async: false,
				dataType: 'json',
				url: SERVICE_URL_DOMAIN + "/Taller.xsodata/Taller("+parseInt(idTaller)+")",
				success: function (data) {
					resolve(data.d.banner);
				},
				error: function (xhr, textStatus, errorMessage) {
					reject(errorMessage);
				}
			})	
		});
	}
	
	async _handleDownloadPdf() {//+ecarpiod
		try{
			
			// var imagen=await this._getBannerTaller(this.state.actividad.idTaller);
			var imagen='';//izaregon962{
			if(this.state.actividad.vBannerPdf===1){
				 imagen=await this._getBannerTaller(this.state.actividad.idTaller);
			}//}izaregon962
			var aDocumentContent = imagen?this._getPdfHeader():this._getPdfHeaderSinImagen();
			aDocumentContent = aDocumentContent.concat(this._getInscriptionStatus());
			aDocumentContent = aDocumentContent.concat(this._getPdfWorkshopInfo());
			var nombrePDF=this.state.inscripcion.codigoInscripcion;
			if(this.state.actividad.Formulario.cargaMasiva===1){
				nombrePDF=nombrePDF.substring(11,0);
			}
			this.state.actividad.Formulario.Seccion.results.map(function (aDocumentContent, oSection) {
				if( (oSection.nombre.toUpperCase() !== "Adjunto".toUpperCase()) ){//izarego 408
					if (((oSection.seccionAdultoResponsable === 0) && (oSection.nombre.toUpperCase() !== "Intereses".toUpperCase())) || (oSection.seccionAdultoResponsable === 1 && this.state.actividad.inscritoEsMenor)) {
						aDocumentContent.push(this._getSectionInfo(oSection));
					}else{//izarego 485
						// if(this.state.actividad.Formulario.cargaMasiva===1){//izaregon599
						if(this.state.actividad.Formulario.adultoObligatorio===1){
							aDocumentContent.push(this._getSectionInfo(oSection));
						}
					}
				}//izarego 408
			}.bind(this, aDocumentContent));
			if (this.state.actividad.vAutorizacion===1) {	//izarego817			
				if (this.state.actividad.inscritoEsMenor) {
					aDocumentContent.push(this._getAnexoAutorizacion());
				}
			}
			aDocumentContent.concat(aDocumentContent);			
			var docDefinition = {
				content: aDocumentContent,
				styles: {
					sectionTitle: {
						fontSize: 22,
						bold: true,
						margin: [0, 20, 0, 10]
					}
				},
				images: {
					header: imagen
				}
			};
			window.pdfMake.createPdf(docDefinition).download(nombrePDF + '.pdf');
		}catch(e){
			alert(e);
		}
	
	}

	render() {
		return (
			<ActivityContainer
				actividad={this.state.actividad}
				mibaLogueado={this.state.mibaLogueado}
				procesoInscripcion={this.state.procesoInscripcion}	//izaregon599	
				listInscripciones={this.state.listInscripciones}	//izaregon599			
				formularioProcesado={this.state.inscripcion.formularioProcesado}
				inscripcionConfirmada={this.state.inscripcion.inscripcionConfirmada}
				codigoInscripcion={this.state.inscripcion.codigoInscripcion}
				fechaNacimiento={this.state.inscripcion.fechaNacimiento}
				onLogin={this._mibaLogin}
				viewFormsInscripction={this.viewFormsInscripction}//izaregon683	
				deleteCuposInscripction={this.deleteCuposInscripction}//izaregon683	
				
				inscritoEsMenor={this.state.actividad.inscritoEsMenor}
				adultoObligatorio={this.state.actividad.Formulario.adultoObligatorio}//izaregon412
				onBirthdateChange={this.handleBirthdateChange}
				onInputChange={this.onInputChange}
				enviarImagen={this.enviarImagen}//izaregon408			
				onSubmitForm={this.onSubmitForm}
				captchaIDHandler={this._captchaIDHandler}
				handleDownloadPdf={this._handleDownloadPdf} />
		);
	}
};