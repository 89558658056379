import React, { Component } from 'react';
import '../styles/popup.css';
import $ from 'jquery';
import Popup from "reactjs-popup";
const SERVICE_URL_DOMAIN = "/destinations/GCBA_IOGEN_PU";
class PopUp extends Component {

	constructor(props) {
		super(props);
		this.GCBA_URL_PREFIX = "http://www.buenosaires.gob.ar";
		this.state = { open: true,estado:1 };
		this.closeModal = this.closeModal.bind(this);
		this.style = { cursor: 'pointer', position: 'absolute', display: 'block', padding: '2px 5px', lineHeight: '20px', right: '-10px', top: '-10px', 'font-size': '24px', background: ' #ffffff', 'border-radius': '18px', 'border': '1px solid #cfcece', 'color': '#333333' };
	}


	closeModal() {
		this.setState({ open: false });
	}
	componentDidMount() {

		var oData = {
			idReparticion:this.props.reparticionID
			
		};
		$.ajax({
			type: "post",
			url: SERVICE_URL_DOMAIN + "/MensajePortal.xsjs",
			data: JSON.stringify(oData),
			dataType: 'json',
			cache: false
		})
			.done(function (data, textStatus, jqXHR) {
			
				if (data) {
					this.setState(data);
				}
			}.bind(this))
			.fail(
				function (jqXHR, textStatus, errorThrown) {
					console.log("Error", jqXHR, textStatus, errorThrown);
					alert("An error occurred while retrieving data from the server: " + textStatus);
				}
			).always(console.log('callpop'));
			
	}

	render() {

		if (this.state.estado == 1||this.props.reparticionID===undefined) {// 0 activo, 1 Inactivo
			return null;

		} else {
			return (
				<Popup open={this.state.open}
					closeOnDocumentClick
					onClose={this.closeModal}
					contentStyle={{ padding: "0px", border: "none", "max-width": "600px" }}
					modal >
					<div >
						<a style={this.style} onClick={this.closeModal}>&times;</a>

						<div className="" >

							<div >
								<div className="">
									

									<div >									
										<img src={this.state.imagen} className="popup-imagen" />										
									</div>									
									
								</div>
							</div>
						</div></div>
				</Popup>
			);
		}
		
	}
}

export default PopUp;
