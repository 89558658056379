import React, { Component } from "react";
import $ from "jquery";

const SERVICE_URL_DOMAIN = "/destinations/GCBA_IOGEN_PU";

export class ComboGenerico extends Component {
	render() {
		var isDisabled = (!this.props.items || this.props.items.length === 0);
		var defaultItem = !isDisabled ? this.props.defaultItem : {key: 0, value: 0, caption: '---'}
		return (
			<div id={'filter-' + this.props.nombre}
			className="col-xs-12" style={{padding:"0"}}>
				<span className="text-muted"><strong>{this.props.caption}</strong></span><br></br>
				<select
					id={'filterSelect-' + this.props.nombre}
					disabled={isDisabled}
					value={this.props.selectedValue}
					className="form-control chosen-select mb-4"
					onChange={this.props.onChange}
					data-placeholder="Elegí una opción">
						{defaultItem ? <option key={defaultItem.key} value={defaultItem.value}>{defaultItem.caption}</option> : null}
						{ this.props.items.map(
							function(item) {
								return (<option key={item.key} value={item.value}>{item.caption}</option>);
							}
						)}
				</select>
			</div>
		);	
	}
}
//izaregon717{
export class ComboProvincias extends Component {
	constructor(props) {
		
		super(props);
		if(this.props.ministerioURL==="cultura"){//izaregon929
			this.state = {
				provincias: [{id: "5",descripcion: "Ciudad de Buenos Aires"}]};
		}else{
			this.state = {
				provincias: [{id: "1",descripcion: "Buenos Aires"},
				// {id: "2",descripcion: "Catamarca"},
				// {id: "3",descripcion: "Chaco"},{id: "4",descripcion: "Chubut"},
				{id: "5",descripcion: "Ciudad de Buenos Aires"}
				// ,{id: "6",descripcion: "Córdoba"},
				// {id: "7",descripcion: "Corrientes"},{id: "8",descripcion: "Entre Ríos"},
				// {id: "9",descripcion: "Formosa"},{id: "10",descripcion: "Jujuy"},
				// {id: "11",descripcion: "La Pampa"},{id: "12",descripcion: "La Rioja"},
				// {id: "13",descripcion: "Mendoza"},{id: "14",descripcion: "Río Negro"},
				// {id: "15",descripcion: "Salta"},{id: "16",descripcion: "San Juan"},
				// {id: "17",descripcion: "Santa Cruz"},{id: "18",descripcion: "Santa Fe"},
				// {id: "19",descripcion: "Santiago del Estero"},
				// {id: "20",descripcion: "Tierra del Fuego"},{id: "21",descripcion: "Tucumán"},
				]
			};
		
		}
	}
	
	render() {
		return (
			<ComboProvinciasGenerico
				provincias={this.state.provincias}
				ministerioURL={this.props.ministerioURL}
				seleccionada={this.props.filtros.provincias}
				onChange={this.props.onChange} />
		)
	}
}
class ComboProvinciasGenerico extends Component {
	render() {
		
		function adapt(provincia) {
			return provincia.map(function(d) {return {key: d.id, value: d.id, caption: d.descripcion}});
		};		
		return (
			<ComboGenerico
				nombre="Provincias"
				onChange={this.props.onChange}
				caption={'Provincias'}
				items={adapt(this.props.provincias)}				
				selectedValue={this.props.seleccionada}
				defaultItem={//izaregon929
					(this.props.ministerioURL==="cultura" )?null:
					{
					key: 0,
					value: '',
					caption: 'Todas'
				}} />
				// defaultItem={{
				// 	key: 0,
				// 	value: '',
				// 	caption: 'Todas'
				// }} />
		);
	}
}
//}izaregon717
class ComboDisciplinasGenerico extends Component {
	render() {
		function adapt(disciplinas) {
			return disciplinas.map(function(d) {return {key: d.id, value: d.id, caption: d.descripcion}});
		};
		return (
			<ComboGenerico
				nombre="Disciplinas"
				onChange={this.props.onChange}
				caption={'Disciplinas'}
				items={adapt(this.props.disciplinas)}
				selectedValue={this.props.seleccionada}
				defaultItem={{
					key: 0,
					value: '',
					caption: 'Todas'
				}} />
		);
	}
}
export class ComboDisciplinas extends Component {
	constructor(props) {
		super(props);
		this.state = {
			disciplinas: [],
		};
		this.baseQueryData = {
			$format: 'json',
			/* ???? */
			$filter: 'reciclado eq 0 and reparticion.id eq ' + (this.props.reparticionID ? this.props.reparticionID : '0'), 
			// $filter: 'reparticion.id eq 0',
			$select: 'id,descripcion',
			$orderby: 'descripcion'
		}
	}
	
	fetchDisciplinas(queryData) {
		$.ajax({
			url: SERVICE_URL_DOMAIN + "/MasterService.xsodata/Disciplina",
			data: queryData,
			dataType: 'json',
			cache: false
		})
		.done(
			function(data) {
				if(data && data.d && data.d.results) {
					this.setState({disciplinas: data.d.results});
				}
			}.bind(this)
		)
	}

	componentDidMount() {
		this.fetchDisciplinas(this.baseQueryData);
	}

	render() {
		return (
			<ComboDisciplinasGenerico
				disciplinas={this.state.disciplinas}
				seleccionada={this.props.filtros.disciplina}
				onChange={this.props.onChange} />
		)
	}
}

class ComboSedeGenerico extends Component {
	render() {
		function adapter(sedes) {
			return sedes.map(function(s) {return {key: s.id, value: s.id, caption: s.nombre}});
		};
		return (
			<ComboGenerico
				nombre="Lugar"
				onChange={this.props.onChange}
				caption={'Lugar'}
				items={adapter(this.props.sedes)}
				selectedValue={this.props.seleccionada}
				defaultItem={{
					key: 0,
					value: '',
					caption: 'Todos'
				}} />
		);
	}
}

export class ComboSede extends Component {
	constructor(props) {
		super(props);
		this.state = {
			centros: [],
		};
		if(this.props.reparticionID!=='0'){
			this.baseQueryData = {
				$format: 'json',
				$filter: 'reciclado eq 0 and reparticion.id eq ' + (this.props.reparticionID ? this.props.reparticionID : '0'),			
				$orderby: 'nombre',
				$select: 'id,nombre'
			}
		}else{
			this.baseQueryData = {
				$format: 'json',
				$filter: 'reciclado eq 0 and reparticion.id eq 0',				
				$orderby: 'nombre',
				$select: 'id,nombre'
			}
		}
	  }
	
	fetchSedes(queryData) {
		$.ajax({
			url: SERVICE_URL_DOMAIN + "/MasterService.xsodata/Sede",
			data: queryData,
			dataType: 'json',
			cache: false
		})
		.done(
			function(data) {
				if(data && data.d && data.d.results) {
					//Se retira TODAS de sede, este dato solo funciona para ABM Usuarios, no en filtros
					var index=-1; var newArrary=[]
					for(var i=0;i<data.d.results.length;i++){
						if(data.d.results[i].id==='0'){
							index=i;
						}else{
							newArrary.push(data.d.results[i]);
						}
					}
					if(index>-1){
						data.d.results=newArrary;
					}
					this.setState({centros: data.d.results});
				}
			}.bind(this)
		)
	}

	componentDidMount() {
		this.fetchSedes(this.baseQueryData);
	}

	componentDidUpdate(prevProps) {
		if (this.props.filtros.barrio !== prevProps.filtros.barrio) {
			var queryData = Object.assign({}, this.baseQueryData);
			var filtro=`barrio.id eq ${this.props.filtros.barrio}` + ' and reparticion.id eq '+(this.props.reparticionID );//izaregon920
			// var filtro=`barrio.id eq ${this.props.filtros.barrio}` + 'and idReparticion eq '+(this.props.reparticionID !== '0' ? ` ${this.props.reparticionID}` : '0');//izaregon920
			if (this.props.filtros.barrio !== '') {
				
				queryData = $.extend(queryData, {$filter: filtro})
			}
			this.fetchSedes(queryData);
		}
	}

	render() {
		return (
			<ComboSedeGenerico
				sedes={this.state.centros}
				seleccionada={this.props.filtros.centro}
				onChange={this.props.onChange} />
		)
	}
};

/* class ComboItemGenericoComuna extends Component {
	render() {
		var sComuna = (this.props.comuna.id) ? "Comuna "+this.props.comuna.id : "Todos";
		return (
			<option value={this.props.comuna.id}>{sComuna}</option>
		);
	}
}; */

/* class ComboComunas extends Component {
	constructor(props) {
		super(props);
		this.state = {
			comunas: [],
		};
	  }
	
	_getFilteredCommunes() {
		var aFilteredCommunes = [];
		var aOriginalCommunes = $.extend([], this.state.comunas);
		var aActualActivities = this.props.actividades; 
		if(this.props.withFilter) {
			aOriginalCommunes.map(function(oCommune) {
				if (aActualActivities.some(oActivity => oActivity.comunaSede === oCommune.id))
					aFilteredCommunes.push(oCommune);
			});
		} else {
			aFilteredCommunes = aOriginalCommunes;
		}
		
		return aFilteredCommunes;
	}
	
	componentDidMount() {
		$.ajax({
			url: SERVICE_URL_DOMAIN + "/MasterService.xsodata/Comuna?$format=json",
			dataType: 'json',
			cache: false
		})
		.done( function( data, textStatus, jqXHR ) {
			var aComunas = data && data.d && data.d.results ? data.d.results : [];
			this.setState({comunas: aComunas.sort((a, b) => a.id - b.id)})
		}.bind(this))
		.fail( function( jqXHR, textStatus, errorThrown ) {
			console.log("Error", jqXHR, textStatus, errorThrown );
			alert( "An error occurred while retrieving data from the server: " + textStatus );
		});
	}
	
	render() {
		var aCommunes = this._getFilteredCommunes();
		var aOptions = [
			<ComboItemGenericoComuna key="" comuna={{id:"", nombre: aCommunes.length > 0 ? "Todos" : ""}} />
		];
		aCommunes.map((oComuna) => aOptions.push(<ComboItemGenericoComuna key={oComuna.id} comuna={oComuna} />));
		return (
			<div className="col-xs-12" style={{padding:"0"}}>
				<span className="text-muted"><strong>Comuna</strong></span>
				<select value={this.props.value} className="form-control chosen-select mb-4" onChange={this.props.onChange} data-placeholder="Elegí una opción">
					{aOptions}
				</select>
			</div>
		);	
	}
}; */


export class ComboBarrioGenerico extends Component {
	render() {
		function adapt(barrios) {
			return barrios.map(function(b) {return {key: b.id, value: b.id, caption: b.descripcion}})
		};
		if((document.getElementById('filterSelect-Barrio')!==null)){
			document.getElementById('filterSelect-Barrio').value=this.props.seleccionada;
			}
		return (
				<ComboGenerico
					nombre="Barrio"
					onChange={this.props.onChange}
					caption={'Barrio'}
					items={adapt(this.props.barrios)}
					selectedValue={this.props.seleccionada}
					defaultItem={
						// (this.props.seleccionProvincia )?null:
						{
						key: 0,
						value: '',
						caption: 'Todos'
					}} />
		);
	}
}

export class ComboBarrio extends Component {

	constructor(props) {
		super(props);
		this.state = {
			barrios: [],
		};
		this.baseQueryData = {
			$format: 'json',
			$select: 'id,descripcion',
			$orderby: 'descripcion'
		}
		
	  }

	fetchBarrios(queryData) {
		$.ajax({
			url: SERVICE_URL_DOMAIN + "/MasterService.xsodata/Barrio",
			data: queryData,
			dataType: 'json',
		}).done(
			function(data) {
				if (data && data.d && data.d.results) {
					this.setState({barrios: data.d.results});
				}
			}.bind(this)
		);
	}
	
	componentDidMount() {
		this.fetchBarrios(this.baseQueryData);
	}

	/* componentDidUpdate(prevProps) {
		if (this.props.filtros.disciplina != prevProps.filtros.disciplina) {
			var queryData = Object.assign({}, this.baseQueryData);
			if (this.props.filtros.disciplina != '') {
				//TODO: ACTUALIZAR QUERYDATA PARA AGREGAR EL FILTRO
			}
			this.fetchBarrios(queryData);
		}
	} */
	
	render() {
		// this.props.filtros.barrio=(this.props.filtros.barrio)?this.props.filtros.barrio:"";
		return (
			<ComboBarrioGenerico
				barrios={this.state.barrios}
				seleccionada={this.props.filtros.barrio}
				onChange={this.props.onChange} />
		)
	}
};

class ComboModalidadGenerico extends Component {
	render() {
		function adapt(modalidades) {
			return modalidades.map(function(m) {return {key: m.id, value: m.id, caption: m.descripcion}})
		};
		return (
				<ComboGenerico
					nombre="Modalidad"
					onChange={this.props.onChange}
					caption={'Modalidad'}
					items={adapt(this.props.modalidades)}
					selectedValue={this.props.seleccionada}
					defaultItem={{
						key: 0,
						value: '',
						caption: 'Todas'
					}} />
		);
	}
}

export class ComboModalidad extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalidades: [],
		};
	  }
	
	componentDidMount() {
		// TODO: Filtrar modalidades segun disciplina!
		$.ajax({
			url: SERVICE_URL_DOMAIN + "/MasterService.xsodata/TipoTaller",
			data: {
				$format: 'json',
				$orderby: 'id',
				$filter: "reciclado eq 0 and reparticion.id eq " + (this.props.reparticionID !== undefined ? this.props.reparticionID : 0) 
				// $filter: "reparticion.id eq 0 ",
			},
			dataType: 'json',
			cache: false
		})
		.done(
			function( data, textStatus, jqXHR ) {
				if (data && data.d && data.d.results)
					this.setState({modalidades: data.d.results})
		}.bind(this))
		.fail(function(jqXHR, textStatus) { console.log('ERROR al obtener las modalidades: ' + textStatus); });
	}
	
	render() {
		return (
			<ComboModalidadGenerico
				modalidades={this.state.modalidades}
				seleccionada={this.props.filtros.modalidad}
				onChange={this.props.onChange} />
		);	
	}
};
export class CheckCuposDisponibles extends Component {//izaregon947
	render() {
		return (
			<label>
				<input type="checkbox"	checked={this.props.value}	onChange={this.props.onChange} />
				  Cupos disponibles
			</label>
			
		);		
	}
};
export class CheckInscripcionAbierta extends Component {
	render() {
		return (
			<label>
				<input type="checkbox"	checked={this.props.value}	onChange={this.props.onChange} />
				  Inscripción abierta
			</label>
			
		);		
	}
};

class InputEdad extends Component {
	_onChange(e) {
		if (e.target.value >= 0) {
			this.props.onChange(e);
		}
	}

	render() {
		return (
			<div className="col-xs-12" style={{padding:"0"}}>
				<span className="text-muted"><strong>{this.props.caption}</strong></span><br></br>
				<input type="number" id={this.props.id} className="form-control mb-4" onChange={this._onChange.bind(this)} value={this.props.value} />
			</div>
		);
	}
}

export class InputDesde extends Component {
	render() {
		return (
			<InputEdad caption={"Edad desde:"} id="edadMinId" onChange={this.props.onChange} value={this.props.value}/>
		);		
	}
};

export class InputHasta extends Component {
	render() {
		return (
			<InputEdad caption={"Edad hasta:"} id="edadMaxId" onChange={this.props.onChange} value={this.props.value} />
		);		
	}
};

export class CheckArancel extends Component {
	render() {
		return (
			<label>
				<input type="checkbox"	checked={this.props.value}	onChange={this.props.onChange} />
				  Gratuitas
			</label>
			
		);		
	}
};

