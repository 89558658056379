import React, { Component } from 'react';
import { withRouter } from "react-router";
import queryString from "query-string";
import { ComboDisciplinas, ComboModalidad, ComboBarrio } from './filter-components';
import $ from 'jquery';
import 'jquery-match-height';

class MinisteriesSearch extends Component {

	constructor(props) {
		super(props);
		this.state = {
			filtros: {
				barrio: '',
				disciplina: '',
				modalidad: ''
			}
		};
		this.handleChangeDisciplina = this.handleChangeDisciplina.bind(this);
		this.handleChangeBarrio = this.handleChangeBarrio.bind(this);
		this.handleChangeModalidad = this.handleChangeModalidad.bind(this);	  	  
		this._handleSubmit = this._handleSubmit.bind(this);
	}

	handleChangeDisciplina(event) {
		var filtros = $.extend(this.state.filtros, {disciplina: event.target.value})
		this.setState(filtros);
	}


	handleChangeBarrio(event) {
		var filtros = $.extend(this.state.filtros, {barrio: event.target.value})
		this.setState(filtros);
	}


	handleChangeModalidad(event) {
		var filtros = $.extend(this.state.filtros, {modalidad: event.target.value})
		this.setState(filtros);
	}

	_handleSubmit() {
		var filtros = {};
		Object.keys(this.state.filtros).forEach(
			key => {
				if (this.state.filtros[key] != '') { //*ecarpiod
					filtros[key] = this.state.filtros[key];
				}
			}
		);
		if (!!Object.keys(filtros).length) {
			this.props.history.push('/actividades/buscar?' + queryString.stringify(filtros));
		} else {
			alert('Por favor, seleccione por lo menos una opción de busqueda.');
		}
	}

	render() {
		return (
			<div className="container-fluid bg-gray boxShadow">
			<div className="container">
				<form onSubmit={this._handleSubmit} className="form-inline text-center" style={{padding: "20px 0"}}>
					<div className="form-group col-xs-3 col-md-3">
						<ComboDisciplinas filtros={this.state.filtros} reparticionId={0} onChange={this.handleChangeDisciplina} generico={true}/>
					</div>
					<div className="form-group col-xs-3 col-md-3">
						<ComboBarrio filtros={this.state.filtros} onChange={this.handleChangeBarrio}/>
					</div>
					<div className="form-group col-xs-3 col-md-3">
						<ComboModalidad filtros={this.state.filtros} onChange={this.handleChangeModalidad} generico={true}/>					
					</div>									
					<div className="form-group col-xs-3 col-md-3"><br></br>
						<button type="submit" className="btn btn-primary">Buscar</button>
					</div>		
				</form>
			</div>
		</div>
		);
	}
};

	
export const MinisteriesSearchWithRouter = withRouter(MinisteriesSearch);

class MinisteriesTitle extends Component {
	render() {
		return (
			<div className="container text-center" style={{marginTop: "20px"}}>
				<h1>Buscar por área de gobierno</h1>
				<p></p>
			</div>	
		);
	}
};

class MinisteryTile extends Component {
	constructor(props) {
		super(props);
		this.state = {disabled: (this.props.ministerio["estado.id"] == "1")};
		this._handleMinisteryPress = this._handleMinisteryPress.bind(this);
	}

	_handleMinisteryPress() {
		if (!this.state.disabled) {
			// this.props.history.push('/ministerio/' + this.props.ministerio.url);
			this.props.history.push('/area/' + this.props.ministerio.url);//izaregon535
		}
	}
	
	render () {
		var sContainerClassName = "col-xs-12 col-sm-6 col-md-4 col-lg-3 m-landing-ministerio " + this.props.ministerio.clase;
		if (this.state.disabled)
			sContainerClassName += " disabled";
		if(this.props.ministerio.logo!=''){
			return (
			<div className={sContainerClassName} onClick={this._handleMinisteryPress}>
					<div className="thumbnail text-center">
					{/* <span className="promocion-cultural-img" 
							style ={{ backgroundImage: `url(${this.props.ministerio.logo})` }}>
							</span>	 */}
						{/* <img url={`${this.props.ministerio.logo}`} alt={this.props.ministerio.titulo} className="img-circle mw-30 p5" /> */}
						<img src={`${this.props.ministerio.logo}`} alt={this.props.ministerio.titulo} className="img-circle mw-30 p6" />
						<div className="caption">
							<h3>{this.props.ministerio.titulo}</h3>
							<p className="text-muted">{this.props.ministerio.detalle}</p>
						</div>
					</div>
				</div>
			);
		}else{
			return (
				<div className={sContainerClassName} onClick={this._handleMinisteryPress}>
					<div className="thumbnail text-center">
						<img src={`img/ministerios/${this.props.ministerio.icono}`} alt={this.props.ministerio.titulo} className="img-circle mw-30 p5" />
						<div className="caption">
							<h3>{this.props.ministerio.titulo}</h3>
							<p className="text-muted">{this.props.ministerio.detalle}</p>
						</div>
					</div>
				</div>
			);
		}
	}
};

const MinisteryTileWithRouter = withRouter(MinisteryTile);

class MinisteriesContainer extends Component {
	componentDidUpdate() {
		$(function () {
			$('.thumbnail').matchHeight();
		});
	}

	render () {
		return (
			<div className="container">
				<div className="container-fluid m-ministeries-tiles-container">
					{this.props.ministerios.map(
						function(ministerio) {
							return(<MinisteryTileWithRouter ministerio={ministerio} key={ministerio.id} />);
						})}
				</div>
			</div>
		)
	}
};


class JumbotronLanding extends Component {
	render() {
		return (
			<div className="jumbotron m-jumbotron-landing">
				<div className="container">
					<h1 className="text-white">Inscribite con el nuevo sistema</h1>
					<p className="lead text-white">

					</p>
				</div>
			</div>
		);
    }
}

export class PortalUnicoLanding extends Component {
	constructor(props) {
		super(props);
		this.state = {
			overlayIsActive: true,
			ministerios: []
		};
	  }
	
	componentDidMount() {
		window.scrollTo(0,0);
		var odataUrl = "/destinations/GCBA_IOGEN_PU";
		this.props.loadingStateCounter.increase();
		$.ajax({
			url: odataUrl + "/MasterService.xsodata/Ministerio?$filter=estado.id ne 2 and reciclado ne 1&$select=id,descripcion,titulo,icono,url,logo,clase,detalle,estado.id&$orderby=orden" ,
			dataType: 'json',
			cache: false
		})
		.done( function( data, textStatus, jqXHR ) {
			if (data && data.d && data.d.results) {
				this.setState({
					ministerios: data.d.results.sort((a, b) => a.orden - b.orden)
				})
			}
			this.props.loadingStateCounter.decrease();
		}.bind(this))
		.fail( function( jqXHR, textStatus, errorThrown ) {
			console.log("Error", jqXHR, textStatus, errorThrown );
			alert( "An error occurred while retrieving data from the server: " + textStatus );
		});
	}
	
	render () {
		return (
			<div>
				<JumbotronLanding />
				<MinisteriesSearchWithRouter />
				<MinisteriesTitle />
				<MinisteriesContainer ministerios={this.state.ministerios} />
			</div>
		);
	}
};
