import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

class BreadcrumbLink extends Component {
	render() {
		return (
			<li>
				<Link className="clickable-link" to={this.props.url}>{this.props.descripcion}</Link>
			</li>
		);		
	}
}

const BreadcrumbLinkWithRouter = withRouter(BreadcrumbLink);

class BreadcrumbLinkLanding extends Component {
	render() {
		return <BreadcrumbLinkWithRouter url='/' descripcion={'Home'} />
	}
}

class BreadcrumbLinkReparticion extends Component {
	render() {
		return (
            <BreadcrumbLinkWithRouter
				url={'/actividades/' + this.props.reparticion.id}
                descripcion={this.props.reparticion.descripcion} />
		);
	}
}

class BreadcrumbLinkMinisterio extends Component {
	render() {
		return (
            <BreadcrumbLinkWithRouter
				url={'/ministerio/' + this.props.ministerio.url}
                descripcion={this.props.ministerio ? this.props.ministerio.descripcion : ''}
                /* baseURL={'/ministerio'}
                relURL={this.props.ministerio ? this.props.ministerio.url : (this.props.URL ? this.props.URL : '')} */ />
		);
	}
}



class Breadcrumb extends Component {
	render() {
		var descripcion = this.props.descripcion;
	  	return (
			<ol className="breadcrumb">
				<div className="container">
					{this.props.children}
					<li className="active">
						{this.props.beSmall ? <small>{descripcion}</small> : descripcion}
					</li>
				</div>
			</ol>
	  	);
	}
}






class Jumbotron extends Component {
	render() {
		return (
			<div className="jumbotron jumbotron-main jumbotron-small m-jumbotron-promocion-cultura mb-4" style ={{ backgroundImage: `url(${this.props.banner})` }}>
				<nav role="navigation">
					{this.props.children}		
				</nav>
				<div className="container">				
					<div className="area-title">
						<h1 style={{color:(this.props.titulo==='Finestec')?"black" : "white"}}//izaregon543
						>{this.props.titulo}</h1>
						<p className="lead">{this.props.detalle}</p>
					</div>
				</div>				
			</div>
		);
	}
}



export { Jumbotron, Breadcrumb, BreadcrumbLinkLanding, BreadcrumbLinkMinisterio, BreadcrumbLinkReparticion, BreadcrumbLinkWithRouter };